<template>
  <x-row :class="computedClasses">
    <x-col>
      <slot />
    </x-col>

    <x-col auto>
      <slot name="after" />
    </x-col>
  </x-row>
</template>

<script>
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'

export default {
  name: 'XFilterStatusBar',
  components: {
    XRow,
    XCol
  },
  props: {
    valign: {
      type: String,
      default: 'middle',
      validator: (value) => ['top', 'middle', 'bottom'].includes(value)
    }
  },
  computed: {
    computedClasses () {
      return [
        'x-filter-status-bar',
        `x-filter-status-bar--valign-${this.valign}`
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-filter-status-bar
    &--valign-top
      align-items flex-start

      .row
        align-items flex-start

    &--valign-middle
      align-items center

      .row
        align-items center

    &--valign-bottom
      align-items flex-end

      .row
        align-items flex-end

    > .col
      height 100%

    .row
      height 100%
</style>
