<template>
  <div class="x-file-upload">
    <input
      id="upload"
      ref="inputFile"
      type="file"
      class="x-file-upload__default"
      v-bind="$attrs"
      @change="onUpload($event.target.files)"
    >

    <label
      for="upload"
      class="x-file-upload__wrapper"
    >
      <slot>
        <x-input
          :placeholder="placeholder"
          class="x-file-upload__mask"
          :class="{ 'x-file-upload__mask_choosed': fileChosen }"
          :failed="failed"
          :passed="passed"
        />
        <x-btn
          v-if="hasReset && fileChosen"
          class="x-file-upload__clear"
          prepend="x"
          @click.native.prevent="clearFile"
        />
        <x-btn
          v-if="!(hasReset && fileChosen)"
          primary
          :disabled="disabled"
          class="x-file-upload__button"
        >
          Выбрать файл
        </x-btn>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'XFileUpload',
  inheritAttrs: false,
  props: {
    uploaded: Boolean,
    disabled: Boolean,
    hasReset: Boolean,
    forceClear: Boolean,
    failed: Boolean,
    passed: Boolean
  },
  data () {
    return {
      fileChosen: false,
      fileName: ''
    }
  },
  watch: {
    uploaded (uploaded) {
      if (!uploaded) return
      this.clearFile()
    }
  },
  computed: {
    placeholder () {
      return this.fileChosen ? this.fileName : 'Файл не выбран'
    }
  },
  methods: {
    async onUpload (files) {
      if (!files.length) return

      if (!this.forceClear) {
        this.fileName = [...files].map(file => file.name).join(', ')
        this.fileChosen = true
      }

      this.$emit('upload', files)

      if (this.forceClear) {
        await this.$nextTick()

        this.clearField()
      }
    },
    clearField () {
      this.fileName = ''
      this.fileChosen = false
      this.$refs.inputFile.value = ''
    },
    clearFile () {
      this.clearField()
      this.$emit('reset')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-file-upload
    position relative
    cursor pointer

    &__default
      position absolute
      z-index 1
      width 100%
      top 0
      left 0
      right 0
      bottom 0
      font-size 0
      cursor pointer
      opacity 0

    &__wrapper
      display flex
      cursor pointer

    &__mask
      text-overflow ellipsis
      pointer-events none

      &_choosed
        width 100%

        >>> .x-input
          &::placeholder
            color #3c3c47

      >>> .x-input__border
        border-radius 4px 0 0 4px

    &__button
      flex-shrink 0
      border-radius 0 5px 5px 0
      pointer-events none

    &__clear
      border-radius 0 4px 4px 0
</style>
