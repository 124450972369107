<template>
  <x-card class="x-stub-card mb-5">
    <x-heading
      level="3"
      class="mb-6"
    >
      {{ card.title }}
    </x-heading>

    <x-bullet-list
      :items="card.list"
      icon-color="success"
    />

    <x-btn
      v-if="card.footer"
      @click="$emit(`action-${card.footer.action}`)"
    >
      {{ card.footer.button }}
    </x-btn>

    <img
      v-if="card.image"
      class="x-stub-card__image"
      :src="imagePath"
    >
  </x-card>
</template>

<script>
import XHeading from '@/components/common/XHeading/XHeading'
import XBulletList from '@/components/common/XBulletList/XBulletList'

export default {
  name: 'XStubCard',
  components: {
    XHeading,
    XBulletList
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  computed: {
    imagePath () {
      return require(`@/assets/images/svg/${this.card.image}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-stub-card
    position relative
    min-height 288px
    overflow hidden

    &__image
      position absolute
      top 0
      right 0
</style>
