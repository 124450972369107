<template>
  <x-page
    card
    title="Товары на складах"
    class="x-supply-calendar"
  >
    <x-heading
      level="5"
      class="mb-5"
    >
      Остатки на складах
    </x-heading>

    <x-alert
      type="info"
      class="mb-5"
    >
      В настоящий момент вы можете выгрузить остатки на складе в&nbsp; <strong>Нарофоминске</strong>. В ближайшее время функционал будет расширен
    </x-alert>

    <x-products-export-form />
  </x-page>
</template>

<script>
import XHeading from '@/components/common/XHeading/XHeading'
import XProductsExportForm from './components/XProductsExportForm/XProductsExportForm'

export default {
  name: 'XProducts',
  components: {
    XHeading,
    XProductsExportForm
  }
}
</script>
