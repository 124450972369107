<template>
  <x-loader v-if="isLoading" />
  <ul
    v-else
    class="x-time-slots-list"
  >
    <li
      v-for="(slot, key) in data"
      :key="key"
      class="x-time-slots-list__item"
    >
      <x-time-slot
        v-model="checked"
        :data="slot"
        v-on="$listeners"
      />
    </li>
  </ul>
</template>

<script>
import XTimeSlot from '../XTimeSlot/XTimeSlot'

export default {
  name: 'XTimeSlotsList',
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    XTimeSlot
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    isLoading: Boolean
  },
  data () {
    return {
      checked: this.value
    }
  },
  watch: {
    value (value) {
      this.checked = value
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-time-slots-list
    margin 0
    padding 0
    list-style none

    &__item
      margin-bottom 4 * $space
</style>
