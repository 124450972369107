export default {
  agents: {
    id: 'agents',
    label: 'Контрагент',
    component: 'x-select',
    props: {
      options: [],
      label: 'name',
      trackBy: 'id'
    }
  }
}
