import { statuses } from '@/shared/constants/statuses'

const { ACCEPTED, DIVERGENCE, CONSIDER, CANCEL_BY_SELLER, CANCEL_BY_WAREHOUSE } = statuses

export const color = {
  [ACCEPTED]: 'success-lighter',
  [DIVERGENCE]: 'warning-light',
  [CONSIDER]: 'accent-light',
  [CANCEL_BY_SELLER]: 'accent-2-light',
  [CANCEL_BY_WAREHOUSE]: 'error-lighter'
}

export const getColor = (status) => color[status]
