<template>
  <div class="x-create-supply-file-uploader">
    <!-- TODO Add ability to pass append slot for label in x-form-item -->
    <x-label>
      Номенклатура
      <template #append>
        <x-icon
          v-tooltip="{ content: 'Hоменклатура должна быть в формате excel' }"
          class="ml-1 gray--text"
          style="cursor: pointer"
          size="xs"
          name="info"
        />
      </template>
    </x-label>
    <x-file-upload
      multiple
      force-clear
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      :failed="failed"
      :passed="passed"
      :disabled="isLoading"
      @upload="handleUpload"
    />

    <ul class="x-create-supply-file-uploader__list">
      <li
        v-for="file in value"
        :key="file.id"
        class="x-create-supply-file-uploader__item"
      >
        {{ file.title }}
        <button
          class="x-create-supply-file-uploader__button"
          type="button"
          :disabled="isLoading"
          @click="deleteAttachment(file)"
        >
          <x-icon
            class="x-create-supply-file-uploader__icon"
            size="xxs"
            name="x"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import XGet from '@/utils/object/XGet'
import XFileUpload from '@/components/interface/XFileUpload/XFileUpload'
import repository from '../../../../supply-calendar.repository'

export default {
  name: 'XCreateSupplyFileUploader',
  components: {
    XFileUpload
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    failed: Boolean,
    passed: Boolean
  },
  data () {
    return {
      selfValue: this.value,
      isLoading: false
    }
  },
  watch: {
    value (value) {
      this.selfValue = value
    }
  },
  methods: {
    async handleUpload (files) {
      try {
        this.isLoading = true

        for (const file of files) {
          const formData = new FormData()

          formData.append('file', file)
          formData.append('title', file.name)

          const response = await repository.uploadAttachment(formData)

          this.$emit('upload-attachment', XGet(response, 'data'))
        }
      } finally {
        this.isLoading = false
      }
    },

    async deleteAttachment (file) {
      try {
        this.isLoading = true

        await repository.deleteAttachment(file.id)

        this.$emit('delete-attachment', file.id)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-create-supply-file-uploader
    &__list
      margin 0
      margin-left -3 * $space
      padding 0
      list-style none

    &__item
      margin-top $space
      margin-left 3 * $space
      color: $colors.primary

    &__button
      &:hover
        .x-create-supply-file-uploader__icon
          color: $colors.gray-darker

    &__icon
      vertical-align middle
      color: $colors.gray-dark
      transition color .3s
</style>
