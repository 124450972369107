import router from '@/router'
import dayjs from 'dayjs'
import {
  getCookie,
  setCookie,
  removeCookie
} from './cookie'

const apiTokenStorageId = 'xw_token'
const currentDomain = process.env.VUE_APP_CURRENT_ROOT_DOMAIN

export const getApiToken = () => getCookie(apiTokenStorageId)

export const setApiToken = (token) => {
  setCookie(apiTokenStorageId, token, {
    expires: dayjs().add(1, 'week')
  })
}

export const removeApiToken = () => {
  removeCookie(apiTokenStorageId, { domain: '.' + currentDomain })
}

const passportUrl = process.env.VUE_APP_PASSPORT_URL

export const redirectOnPassport = (params = {}) => {
  const { pending } = router.history
  let { currentRoute } = router
  currentRoute = pending || currentRoute

  const { resolved } = router.resolve({
    ...currentRoute,
    query: {
      ...currentRoute.query,
      cabinet_type: undefined,
      xw_token: undefined
    }
  })

  const returnUrl = new URL(resolved.fullPath, location.origin)

  const appendPath = params.path || ''
  const redirectUrl = `${passportUrl + appendPath}?return_url=${returnUrl}`

  if (params.target) {
    window.open(redirectUrl, params.target)
    return
  }
  window.location = redirectUrl
}
