
import { statusFiltersOptions } from '@/shared/constants/statuses'
import { defaultAllOption } from '@/app.shared'

export default [{
  id: 'status',
  component: 'x-select',
  label: 'Статус',
  props: {
    options: [defaultAllOption, ...statusFiltersOptions],
    label: 'name',
    trackBy: 'id'
  }
}, {
  id: 'agents',
  label: 'Контрагент',
  component: 'x-select',
  props: {
    options: [defaultAllOption],
    label: 'name',
    trackBy: 'id'
  }
}, {
  id: 'date',
  label: 'Дата поставки',
  component: 'x-date-picker',
  props: {
    placeholder: 'Выберите дату'
  }
}, {
  id: 'id',
  label: 'Поиск',
  component: 'x-search',
  props: {
    placeholder: 'По ID заявки'
  }
}]
