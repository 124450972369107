import Vue from 'vue'
import axios from 'axios'
import { getApiToken, removeApiToken, redirectOnPassport } from '@/utils/auth/shared'

function isNetworkError (err) {
  return !!err.isAxiosError && !err.response
}

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API
})

_axios.interceptors.request.use(
  function (config) {
    const token = getApiToken()

    if (token) {
      config.headers['X-Xway-Auth'] = token
    }

    return config
  },

  function (error) {
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  function (response) {
    return response
  },

  function (error) {
    if (isNetworkError(error)) {
      Vue.$toast.error('Ошибка сети')
      return
    }

    if (error.response) {
      if (error.response.status === 403) {
        removeApiToken()
        redirectOnPassport()

        return
      }

      if (error.response.status !== 400) {
        const err = error.response?.data

        Vue.$toast.error(err?.detail ? err.detail : (err.errorMessage || err.message))
      } else {
        const data = error.response?.data

        return Promise.reject(data)
      }
    }

    return Promise.reject(error)
  }
)

export default _axios
