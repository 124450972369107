import { formatDate, addDays } from '@/helpers/dates'

export const ESTIMATED_DATE_OFFSET = 4

export const getDefaultModel = () => ({
  warehouse: '',
  agent: '',
  day_period: '',
  estimated_delivery_date: formatDate(addDays(new Date(), ESTIMATED_DATE_OFFSET), 'YYYY-MM-DD'),
  attachments: [],
  pallets_count: '',
  pallets_overall_volume: '',
  boxes_count: '',
  boxes_overall_volume: '',
  goods_count: '',
  barcode: false
})
