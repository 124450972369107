import { getApiToken, redirectOnPassport } from '@/utils/auth/shared'

const unauthorized = (to, from, next) => {
  if (!getApiToken()) {
    next()
    return
  }
  next({ name: 'supply.calendar' })
}

const authorized = (to, from, next) => {
  if (getApiToken()) {
    next()
    return
  }

  redirectOnPassport()
}

export {
  unauthorized,
  authorized
}
