import { PLANS_CHANGED, CHOOSE_ANOTHER_DATE, SELF_REASON, getReasonByType } from '../../shared'

export default [{
  id: PLANS_CHANGED,
  value: PLANS_CHANGED,
  title: getReasonByType(PLANS_CHANGED)
}, {
  id: CHOOSE_ANOTHER_DATE,
  value: CHOOSE_ANOTHER_DATE,
  title: getReasonByType(CHOOSE_ANOTHER_DATE)
}, {
  id: SELF_REASON,
  value: SELF_REASON,
  title: getReasonByType(SELF_REASON)
}]
