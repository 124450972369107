<template>
  <x-layout
    id="app"
    v-bind="layoutProps"
    @logout="handleLogout"
  >
    <router-view :user="user" />
  </x-layout>
</template>

<script>
import RouteNames from '@/router/names'
import repository from './app.repository'

export default {
  data () {
    return {
      isLoading: true,
      menu: [],
      user: {}
    }
  },
  computed: {
    layoutProps () {
      const tariff = this.user.tariff?.name && {
        name: this.user.tariff.name,
        expiresAt: this.user.tariff_expires_at
      }

      return {
        loading: this.isLoading,
        menuItems: this.menu,
        tariff,
        userEmail: this.user.email,
        userName: this.user.name
      }
    },
    isStubPage () {
      return this.$route.name === RouteNames.STUB
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        this.isLoading = true

        await Promise.all([this.getProfile(), this.getMenu()])
      } finally {
        this.isLoading = false
      }
    },
    async getProfile () {
      const { data: { data } } = await repository.getProfile()

      this.user = data ?? {}

      if (!this.user.client.lkp_contract_is_active) {
        if (this.isStubPage) return

        this.$router.push({ name: RouteNames.STUB })
      } else {
        if (this.isStubPage) {
          this.$router.push({ name: RouteNames.CALENDAR })
        }
      }
    },
    async getMenu () {
      const { data: { data } } = await repository.getMenu()

      this.menu = data ?? []
    },
    handleLogout () {
      repository.logout()
    }
  }
}
</script>

<style lang="stylus">
  button
    margin 0
    padding 0
    background none
    border none
    cursor pointer

  // TODO Fix in ecosystem
  .x-checkbox--labeled
    height auto!important
    padding 0!important
    background none!important

    &.x-checkbox--checked
      color inherit!important

  // TODO fix in ecosystem
  .x-select
    .multiselect__single
      background inherit!important

    .multiselect--disabled
      cursor: not-allowed

      ~ .x-select__border
        border-color $colors.gray-light!important

    &--hovered
      .multiselect--disabled ~ .x-select__border
        border-color $colors.gray-light!important
</style>
