<template>
  <div :class="computedClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'XCol',
  props: {
    col: {
      type: String,
      default: null
    },
    auto: Boolean
  },
  computed: {
    computedClasses () {
      return [
        'col',
        { 'col-auto': this.auto },
        { [`col-${this.col}`]: this.col }
      ]
    }
  }
}
</script>
