<template>
  <x-page
    title="Фулфилмент"
    class="x-stub"
  >
    <x-stub-card
      v-for="(card, idx) in cards"
      :key="idx"
      :card="card"
      @action-connect="handleConnect"
    />
  </x-page>
</template>

<script>
import XStubCard from './components/XStubCard/XStubCard'
import XConfirm from '@/components/modals/XConfirm/XConfirm'
import repository from './stub.repository'
import cards from './cards'

export default {
  name: 'XStub',
  components: {
    XStubCard
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      cards
    }
  },
  methods: {
    async handleConnect () {
      await repository.sendToBitrix()

      this.$dialog.show(XConfirm, {
        props: () => ({
          title: 'Спасибо, Ваша заявка отправлена',
          message: ''
        })
      })
    }
  }
}
</script>
