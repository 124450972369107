<template>
  <x-form
    class="x-cancel-supply-form"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="mb-3 text font-weight-bold">Укажите причину отмены</div>
    <x-form-item
      name="Причина отмены"
      rules="required"
    >
      <x-radio-group
        v-model="model.reason"
        :fields="fields"
        name="reasons"
      />
    </x-form-item>

    <x-form-item
      v-if="showSelfReason"
      name="Причина отмены"
      rules="required"
    >
      <x-textarea v-model="model.selfReason" />
    </x-form-item>
  </x-form>
</template>

<script>
import XForm from '@/components/interface/XForm/XForm'
import XRadioGroup from '@/components/interface/XRadioGroup/XRadioGroup'
import XTextarea from '@/components/interface/XTextarea/XTextarea'
import fields from './fields'
import { SELF_REASON } from '../../shared'

export default {
  name: 'XCancelSupplyForm',
  components: {
    XForm,
    XRadioGroup,
    XTextarea
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fields
    }
  },
  computed: {
    showSelfReason () {
      return this.model.reason === SELF_REASON
    }
  }
}
</script>
