<template>
  <x-row :class="computedClasses">
    <x-col>
      <x-row>
        <slot />
      </x-row>
    </x-col>

    <x-col auto>
      <x-btn
        :disabled="disabled"
        outline
        prepend="x"
        @click="$emit('clear')"
      >
        Очистить всё
      </x-btn>
    </x-col>
  </x-row>
</template>

<script>
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'

export default {
  name: 'XFilterBlock',
  components: {
    XRow,
    XCol
  },
  props: {
    valign: {
      type: String,
      default: 'middle',
      validator: (value) => ['top', 'middle', 'bottom'].includes(value)
    },
    disabled: Boolean
  },
  computed: {
    computedClasses () {
      return [
        'x-filter-block',
        `x-filter-block--valign-${this.valign}`
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-filter-block
    &--valign-top
      align-items flex-start

    &--valign-middle
      align-items center

    &--valign-bottom
      align-items flex-end
</style>
