<template>
  <x-form
    ref="form"
    class="x-create-supply-details-form"
    v-on="$listeners"
  >
    <p class="mt-0 mb-5 gray-darker--text">
      Контрагент: {{ agent }}<br>
      Склад: {{ warehouse }}<br>
      Дата: {{ date }}, {{ period }}<br>
      Доступно паллет: {{ pallets }} (максимум {{ goods }} товаров)
    </p>

    <x-heading
      class="mb-5"
      level="5"
    >
      Укажите детали поставки
    </x-heading>

    <x-row class="x-create-supply-details-form__details">
      <x-col
        class="x-create-supply-details-form__order"
        col="6"
      >
        <x-row class="mb-3">
          <x-col col="12">
            <x-checkbox
              v-model="hasPallets"
              label="В поставке паллеты"
            />
          </x-col>
        </x-row>

        <x-row
          v-if="hasPallets"
          class="mb-3"
        >
          <x-col
            class="x-create-supply-details-form__link"
            col="8"
          >
            <x-form-item
              :dot="false"
              :rules="computedPalletsRules"
              v-slot="{ failed, passed }"
              label="Кол-во паллет"
              name="Кол-во паллет"
              vid="pallets_count"
            >
              <x-input
                v-model.number="model.pallets_count"
                :failed="failed"
                :passed="passed"
                placeholder="Количество паллет"
              />
            </x-form-item>
          </x-col>

          <x-col col="4">
            <x-form-item
              :dot="false"
              :rules="computedVolumeRules"
              v-slot="{ failed, passed }"
              label="Куб. м."
              name="Куб. м."
              vid="pallets_overall_volume"
            >
              <x-input
                v-model="model.pallets_overall_volume"
                :failed="failed"
                :passed="passed"
                placeholder="Куб. м."
              />
            </x-form-item>
          </x-col>
        </x-row>

        <x-row class="mb-3">
          <x-col col="12">
            <x-checkbox
              v-model="hasBoxes"
              label="В поставке коробки"
            />
          </x-col>
        </x-row>

        <x-row
          v-if="hasBoxes"
          class="mb-3"
        >
          <x-col
            class="x-create-supply-details-form__link"
            col="8"
          >
            <x-form-item
              :dot="false"
              :rules="computedBoxRules"
              v-slot="{ failed, passed }"
              label="Количество коробок"
              name="Количество коробок"
              vid="boxes_count"
            >
              <x-input
                v-model.number="model.boxes_count"
                :failed="failed"
                :passed="passed"
                placeholder="Количество коробок"
              />
            </x-form-item>
          </x-col>

          <x-col col="4">
            <x-form-item
              :dot="false"
              :rules="computedVolumeRules"
              v-slot="{ failed, passed }"
              label="Куб. м."
              name="Куб. м."
              vid="boxes_overall_volume"
            >
              <x-input
                v-model="model.boxes_overall_volume"
                placeholder="Куб. м."
                :failed="failed"
                :passed="passed"
              />
            </x-form-item>
          </x-col>
        </x-row>

        <x-row class="mb-3 mt-8">
          <x-col col="12">
            <x-form-item
              :dot="false"
              :rules="computedGoodsRules"
              v-slot="{ failed, passed }"
              label="Кол-во товаров в поставке"
              name="Кол-во товаров в поставке"
              vid="goods_count"
            >
              <x-input
                v-model.number="model.goods_count"
                :disabled="!hasPallets && !hasBoxes"
                :failed="failed"
                :passed="passed"
                placeholder="Кол-во товаров в поставке"
              />
            </x-form-item>
          </x-col>
        </x-row>

        <x-row class="mb-3">
          <x-col col="12">
            <x-checkbox
              v-model="model.barcode"
              label="Товары с штрихкодом"
            />
          </x-col>
        </x-row>

        <x-row>
          <x-col col="12">
            <x-form-item
              :dot="false"
              hide-label
              name="Номенклатура"
              rules="required"
              vid="attachments"
              v-slot="{ failed, passed }"
            >
              <x-create-supply-file-uploader
                :value="model.attachments"
                :failed="failed"
                :passed="passed"
                v-on="$listeners"
              />
            </x-form-item>
          </x-col>
        </x-row>
      </x-col>

      <x-col
        class="x-create-supply-details-form__result"
        col="6"
      >
        <x-row class="mb-3">
          <x-col col="12">
            <x-price-calculator :model="model" />
          </x-col>
        </x-row>
      </x-col>
    </x-row>

    <x-row>
      <x-col col="6">
        <x-btn
          type="button"
          color="gray"
          block
          @click="onHandleBackClick"
        >
          Назад
        </x-btn>
      </x-col>

      <x-col col="6">
        <x-btn
          block
          :loading="isLoading"
          :disabled="computedDisabled"
        >
          Создать заявку
        </x-btn>
      </x-col>
    </x-row>
  </x-form>
</template>

<script>
import { formatDate } from '@/helpers/dates'
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'
import XForm from '@/components/interface/XForm/XForm'
import XHeading from '@/components/common/XHeading/XHeading'
import XCreateSupplyFileUploader from '../XCreateSupplyFileUploader/XCreateSupplyFileUploader'
import XPriceCalculator from '../XPriceCalculator/XPriceCalculator'

const numberRules = 'integer|isNumber|greaterThanZero'

export default {
  name: 'XCreateSupplyDetailsForm',
  components: {
    XRow,
    XCol,
    XForm,
    XHeading,
    XCreateSupplyFileUploader,
    XPriceCalculator
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    slots: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    isDisabled: Boolean
  },
  data () {
    return {
      hasPallets: false,
      hasBoxes: false
    }
  },
  watch: {
    errors (value) {
      this.$refs.form.setErrors(value)
    }
  },
  computed: {
    date () {
      return formatDate(this.model.estimated_delivery_date)
    },
    agent () {
      return this.model.agent?.name
    },
    warehouse () {
      return this.model.warehouse?.name
    },
    period () {
      return this.model.day_period
    },
    selectedSlot () {
      return this.slots.find(s => s.title === this.period)
    },
    pallets () {
      return parseInt(this.selectedSlot?.pallets, 10)
    },
    goods () {
      return parseInt(this.selectedSlot?.goods, 10)
    },
    computedRules () {
      return `required|${numberRules}`
    },
    computedPalletsRules () {
      return `${this.computedRules}|max_value:${this.pallets}`
    },
    computedGoodsRules () {
      return `${this.computedRules}|max_value:${this.goods}`
    },
    computedBoxRules () {
      return this.computedRules
    },
    computedVolumeRules () {
      return 'required|isNumber|greaterThanZero'
    },
    isLoading () {
      return this.isDisabled
    },
    computedDisabled () {
      const { attachments } = this.model

      return !attachments.length || (!this.hasBoxes && !this.hasPallets)
    }
  },
  methods: {
    onHandleBackClick () {
      this.$emit('back')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-create-supply-details-form
    font-size 14px

    &__details
      flex-wrap nowrap
      margin-bottom 10 * $space

    &__order
      padding-right 6 * $space

    &__result
      padding-left 5 * $space
      border-left: 1px solid $colors.gray-light

    &__link
      >>> .x-input
        &:after
          content ''
          position absolute
          top 50%
          left 100%
          width 3 * $space
          height 1px
          background-color: $colors.gray
</style>
