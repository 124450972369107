export default [{
  title: 'Возможности фулфилмента XWAY',
  list: [
    'Разработаем технологическую карту подготовки товара к отгрузке',
    'Примем ваши товары со штрих-кодом и обеспечим надежное хранение на складе XWAY',
    'Соберём заказ и доставим до склада курьерской службы (Почта России, DPD, Cainiao, Доставка Гуру)',
    'Промаркируем и упакуем в соответствие с требованиями маркетплейса',
    'Подготовим все необходимые документы'
  ],
  image: 'fulfillment.svg'
}, {
  title: 'Вам необходим фулфилмент, если:',
  list: [
    'Не хотите арендовать помещение под склад',
    'Не хотите нанимать дополнительный персонал и обучать его',
    'Не справляетесь с большим числом заказов',
    'Хотите повысить качество и скорость доставки',
    'Хотите сократить расходы на складские и логистические услуги'
  ],
  image: 'shop.svg',
  footer: {
    button: 'Подключить фулфилмент',
    action: 'connect'
  }
}]
