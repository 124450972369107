import { defaultSelectProps } from './shared'

export default {
  agents: {
    props: {
      options: [],
      ...defaultSelectProps
    }
  },
  warehouses: {
    props: {
      options: [],
      ...defaultSelectProps
    }
  }
}
