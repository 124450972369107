<template>
  <x-filter-status-bar>
    <x-row class="align-center">
      <x-col auto>
        Всего: <strong>{{ count }}</strong>
      </x-col>

      <template v-if="!isLoading && count">
        <x-divider
          class="col col-auto m-0 mr-2"
          vertical
        />

        <x-col auto>
          <x-switch
            v-model="selfExpand"
            label="Раскрыть все заявки"
            v-on="$listeners"
          />
        </x-col>
      </template>
    </x-row>

    <template #after>
      <x-btn
        prepend="plus"
        @click="handleSupplyCreate"
      >
        Новая заявка
      </x-btn>
    </template>
  </x-filter-status-bar>
</template>

<script>
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'
import XFilterStatusBar from '@/components/common/XFilterStatusBar/XFilterStatusBar'
import XSwitch from '@/components/interface/XSwitch/XSwitch'

export default {
  name: 'XSupplyCalendarFilterStatusBar',
  inject: ['handleSupplyCreate'],
  components: {
    XRow,
    XCol,
    XFilterStatusBar,
    XSwitch
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    expand: Boolean,
    isLoading: Boolean
  },
  data () {
    return {
      selfExpand: this.expand
    }
  },
  watch: {
    expand (value) {
      this.selfExpand = value
    }
  }
}
</script>
