<template>
  <ul class="x-price-calculator">
    <li
      v-if="isShowComposition"
      class="mb-5"
    >
      <div class="gray-darker--text text">Состав поставки:</div>
      <strong class="gray-darkest-2--text text font-weight-semibold">
        <span v-if="isShowPalletsInformation">
          {{ model.pallets_count }} {{ palletsWord }} ({{ palletsVolume }} м<sup>3</sup>)
        </span>
        <span v-if="isShowAnd">
          и
        </span>
        <span v-if="isShowBoxesInformation">
          {{ model.boxes_count }} {{ boxesWord }} ({{ boxesVolume }} м<sup>3</sup>)
        </span>
      </strong>
    </li>

    <li
      v-if="isShowGoodsCount"
      class="mb-5"
    >
      <div class="gray-darker--text text">Кол-во товаров:</div>
      <strong class="gray-darkest-2--text text font-weight-semibold">{{ goodsCount }}</strong>
    </li>

    <li class="mb-5">
      <div class="gray-darker--text text">Оценка стоимости поставки:</div>
      <strong class="gray-darkest-2--text headline-5 font-weight-semibold">{{ total }} руб.</strong>
    </li>
  </ul>
</template>

<script>
import createWordCase from '@/helpers/word-cases'
import isNumber from '@/utils/number/isNumber'
import normalizeNumber from '@/utils/number/normalizeNumber'
import PriceCalculator from './price-calculator.repository'

const priceCalculator = new PriceCalculator()

export default {
  name: 'XPriceCalculator',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      total: 0
    }
  },
  computed: {
    pallets () {
      return this.model.pallets_count
    },
    palletsWord () {
      return createWordCase({
        few: 'паллет',
        one: 'паллета',
        two: 'паллеты'
      })(this.pallets)
    },
    palletsVolume () {
      return normalizeNumber(this.model.pallets_overall_volume)
    },
    boxes () {
      return this.model.boxes_count
    },
    boxesWord () {
      return createWordCase({
        few: 'коробок',
        one: 'коробка',
        two: 'коробки'
      })(this.boxes)
    },
    boxesVolume () {
      return normalizeNumber(this.model.boxes_overall_volume)
    },
    isShowPalletsInformation () {
      return this.pallets && isNumber(this.palletsVolume) && this.palletsVolume > 0
    },
    isShowBoxesInformation () {
      return !!this.boxes && isNumber(this.boxesVolume) && this.boxesVolume > 0
    },
    isShowComposition () {
      return this.isShowPalletsInformation || this.isShowBoxesInformation
    },
    isShowAnd () {
      return this.isShowPalletsInformation && this.isShowBoxesInformation
    },
    goodsCount () {
      return this.model.goods_count
    },
    isShowGoodsCount () {
      return !!this.goodsCount
    }
  },
  watch: {
    model: {
      deep: true,
      handler () {
        this.total = priceCalculator.getTotal({
          pallets: this.pallets || 0,
          palletsVolume: this.palletsVolume || 0,
          boxes: this.boxes || 0,
          boxesVolume: this.boxesVolume || 0,
          goods: this.goodsCount || 0,
          hasBarcode: this.model.barcode
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-price-calculator
    margin 0
    padding 0
    list-style none
</style>
