<template>
  <x-form
    class="x-attachment-form"
    @submit="handleSubmit"
  >
    <x-icon
      class="x-attachment-form__icon"
      name="download"
    />

    <x-heading
      class="mb-5 text-center"
      level="4"
    >
      Загрузка ТОРГ-12 или УПД
    </x-heading>

    <x-form-item
      class="mb-5"
      v-slot="{ failed, passed }"
    >
      <x-file-upload
        multiple
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        :failed="failed"
        :passed="passed"
        @upload="handleUpload"
      />
    </x-form-item>

    <x-btn
      block
      :disabled="!files"
    >
      Загрузить
    </x-btn>
  </x-form>
</template>

<script>
import XForm from '@/components/interface/XForm/XForm'
import XHeading from '@/components/common/XHeading/XHeading.vue'
import XFileUpload from '@/components/interface/XFileUpload/XFileUpload'

export default {
  name: 'XAttachmentForm',
  components: {
    XForm,
    XHeading,
    XFileUpload
  },
  data () {
    return {
      files: null
    }
  },
  methods: {
    handleUpload (data) {
      this.files = data
    },
    handleSubmit () {
      this.$emit('submit', this.files)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-attachment-form
    padding 0 $space * 8

    &__icon
      display block
      width 72px
      height 72px
      margin 0 auto $space * 5
      color: $colors.primary
</style>
