<template>
  <x-input
    prepend-icon="search"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'XSearch'
}
</script>
