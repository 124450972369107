import { render, staticRenderFns } from "./XPriceCalculator.vue?vue&type=template&id=3bd88c7f&scoped=true&"
import script from "./XPriceCalculator.vue?vue&type=script&lang=js&"
export * from "./XPriceCalculator.vue?vue&type=script&lang=js&"
import style0 from "./XPriceCalculator.vue?vue&type=style&index=0&id=3bd88c7f&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd88c7f",
  null
  
)

export default component.exports