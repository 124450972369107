<template>
  <thead class="grid__head">
    <tr class="grid__row grid__row_heading">
      <th
        v-if="selectable"
        class="grid__heading grid__heading_content_select grid__heading_freezed"
      >
        <div class="grid__heading-in">
          <x-checkbox v-model="selectAll" />
        </div>
      </th>

      <th
        v-for="(item, index) in columns"
        :key="`${item.key}-${index}`"
        class="grid__heading"
        :class="getHeadingClasses(item, index)"
        :style="item.width ? `width: ${item.width}px`: ''"
      >
        <div
          v-if="item.sortable"
          class="grid__heading-in sortable"
          :class="{ 'is-active': item.key === sortKey }"
          @click="$emit('sort-by', item)"
        >
          <slot
            v-if="item.type === 'slotted'"
            name="head"
            :column="item"
            :style="item.headStyle"
          />

          <span
            v-else
            :style="item.headStyle"
            v-html="item.name"
          />

          <x-icon
            class="grid__arrow"
            :name="sortOrders[item.key].key > 0 ? 'sort-asc' : 'sort-desc'"
          />
        </div>

        <div
          v-else
          class="grid__heading-in"
        >
          <slot
            v-if="item.type === 'slotted'"
            name="head"
            :column="item"
            :style="item.headStyle"
          />

          <span
            v-else
            :style="item.headStyle"
            v-html="item.name"
          />
        </div>
      </th>

      <th
        v-if="actions.length"
        class="grid__heading"
      >
        <div class="grid__heading-in">
          {{ actionsHeading }}
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>

export default {
  name: 'XGridHead',
  props: {
    actionsHeading: {
      type: String,
      default: 'Действия'
    },
    columns: {
      type: Array,
      default: () => []
    },
    sortOrders: {
      type: Object,
      default: () => ({})
    },
    sortKey: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    },
    freezed: Boolean,
    selectable: Boolean,
    allSelected: Boolean,
    showActions: Boolean
  },

  computed: {
    freezedColumnsCount () {
      return this.columns.filter(c => c.freezed).length
    },

    selectAll: {
      get () {
        return this.allSelected
      },

      set (checked) {
        this.$emit('check-all', checked)
      }
    }
  },

  methods: {
    getHeadingClasses (item, index) {
      const classes = [item.classes, item.hiddenOnMobile ? 'hidden-on-mobile' : '']

      if (this.freezed) {
        classes.push(item.freezed ? 'grid__heading_freezed' : 'hidden')

        if (index === this.freezedColumnsCount - 1) {
          classes.push('grid__heading_frezed-last')
        }
      }

      if (item.styleType) {
        classes.push(`grid__heading_viewtype_${item.styleType}`)
      }

      return classes
    }
  }
}
</script>

<style lang="stylus">
  // INFO Hack for Firefox collapsing cells
  @-moz-document url-prefix()
    .grid__row
      height 100%!important

    .grid__heading
      height 100%!important
</style>

<style lang="stylus" scoped>
  .grid
    &__head
      &.hide
        height 0
        border 0 none
        opacity 0

        *
          height 0
          padding-top 0
          padding-bottom 0
          border 0 none

    &__row
      &_heading
        @media (max-width 768px)
          display none

        .grid__heading
          border-bottom 1px solid #d7d6d6

    &__heading
      vertical-align top
      font-size 14px
      font-weight normal
      text-align left
      color #7b7b85
      user-select none

      &.hidden
        opacity 0
        pointer-events none

      &_freezed
        background-color #fdfdfd
        pointer-events auto

      &_frezed-last
        .grid__heading-in
          position relative

          &:after
            content ""
            position absolute
            top 0
            bottom 0
            left 100%
            width 20px
            background linear-gradient(90deg, rgba(60, 60, 71, .08) 0%, rgba(60, 60, 71, 0) 100%)

      &_viewtype_numbers
        .grid__heading-in
          text-align right !important
          justify-content flex-end !important

    &__heading-in
      display flex
      align-items center
      height 100%
      padding-right 10px
      padding-left 10px
      padding-bottom 10px
      box-sizing border-box

      &.sortable
        display flex
        align-items flex-start
        cursor pointer

      &.is-active
        color #5b40ff
        font-weight 700

    &__arrow
      flex-shrink 0
      flex-grow 0
      display block
      width 16px
      height 16px
      margin-left 5px
      padding-left 5px
      cursor pointer

      .grid__heading-in.is-active &
        fill #5b40ff
</style>
