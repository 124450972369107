export default class PriceCalculator {
  static palletOneCubePrice = 75
  static boxOneCubePrice = 100
  static withBarcodePrice = 2
  static withoutBarcodePrice = 6

  getPalletsUnloadingCost (pallets, palletsVolume) {
    return pallets * palletsVolume * PriceCalculator.palletOneCubePrice
  }

  getBoxesUnloadingCost (boxes, boxesVolume) {
    return boxes * boxesVolume * PriceCalculator.boxOneCubePrice
  }

  getAcceptanceCost (goods, hasBarcode) {
    const price = hasBarcode ? PriceCalculator.withBarcodePrice : PriceCalculator.withoutBarcodePrice
    return goods * price
  }

  getTotal ({ pallets, palletsVolume, boxes, boxesVolume, goods, hasBarcode }) {
    return this.getPalletsUnloadingCost(pallets, palletsVolume) +
      this.getBoxesUnloadingCost(boxes, boxesVolume) +
      this.getAcceptanceCost(goods, hasBarcode)
  }
}
