import axios from '@/plugins/axios'
import { BASE } from '@/shared/api'
import { removeApiToken, redirectOnPassport } from '@/utils/auth/shared'

export default {
  async getMenu () {
    return await axios.get(`${BASE}/navigation-menu`)
  },

  async getProfile () {
    return await axios.get(`${BASE}/me`)
  },

  logout () {
    removeApiToken()

    redirectOnPassport()
  }
}
