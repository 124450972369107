<template>
  <div class="x-supply-info">
    <x-heading
      class="mb-3"
      level="5"
    >
      Детали поставки
    </x-heading>
    <ul class="x-supply-info__list mb-5">
      <li class="mb-2">
        <span class="gray-darker--text">Контрагент:</span>
        {{ agent }}
      </li>
      <li class="mb-2">
        <span class="gray-darker--text">Дата:</span>
        {{ deliveryDate }}, {{ model.day_period }}
      </li>
      <li class="mb-2">
        <span class="gray-darker--text">Количество паллет:</span>
        {{ model.pallets_count }}
      </li>
      <li class="mb-2">
        <span class="gray-darker--text">Количество товаров:</span>
        {{ model.goods_count }} шт.
      </li>
    </ul>

    <x-heading
      class="mb-3"
      level="5"
    >
      Информация по складу
    </x-heading>
    <ul class="x-supply-info__list">
      <li class="mb-2">
        <span class="gray-darker--text">Склад:</span>
        {{ warehouse }}
      </li>

      <li class="mb-2">
        {{ model.warehouse.address }}
      </li>

      <li class="mb-2">
        <span class="gray-darker--text">Телефон:</span>
        {{ model.warehouse.phone }}
      </li>
    </ul>

    <x-row class="mb-3">
      <x-col col="12">
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A628659df8a385c973df2ca5e9e655dfa8b42bbcb3ced4cbbc213dc2db53fcb40&amp;source=constructor"
          width="100%"
          height="210"
          frameborder="0"
        />
      </x-col>
    </x-row>

    <x-link
      v-if="model.warehouse.map"
      :href="model.warehouse.map"
      primary
      target="_blank"
      @click.native="onDownloadScheme"
    >
      <x-icon
        name="map"
        size="xs"
        style="vertical-align: middle"
      />
      Скачать схему проезда
    </x-link>

    <div class="x-supply-info__action">
      <x-btn
        type="button"
        primary
        block
        @click="$emit('close')"
      >
        Вернуться к списку
      </x-btn>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/dates'
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'
import XHeading from '@/components/common/XHeading/XHeading'
import { sendYmGoal, ymGoals } from '../../../../shared'

export default {
  name: 'XSupplyInfo',
  components: {
    XRow,
    XCol,
    XHeading
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    agent () {
      return this.model.seller_name || this.model?.agent.name
    },
    deliveryDate () {
      return formatDate(this.model.estimated_delivery_date)
    },
    warehouse () {
      return this.model.warehouse.title || this.model.warehouse.name
    }
  },
  methods: {
    onDownloadScheme () {
      sendYmGoal(ymGoals.clickDownloadScheme)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-supply-info
    font-size 14px

    &__list
      margin 0
      padding 0
      list-style none

    &__action
      margin-top 10 * $space
</style>
