<template>
  <x-btn
    class="x-unfold-button"
    ghost
    color="gray"
    v-on="$listeners"
  >
    <slot />
    <x-icon
      name="dropdown-down"
      class="x-unfold-button__icon"
      size="xs"
      :class="{ 'active': active, 'margin': $slots.default }"
      :style="{ stroke: active ? activeColor : color }"
    />
  </x-btn>
</template>

<script>
export default {
  props: {
    active: Boolean,
    color: {
      type: String,
      default: '#3c3c47'
    },
    activeColor: {
      type: String,
      default: '#3c3c47'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-unfold-button
    &__icon
      transition transform .4s

      &.active
        transform rotate(-180deg)

      &.margin
        margin-left 6px
</style>
