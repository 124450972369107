<template>
  <x-dialog no-actions>
    <x-attachment-form @submit="handleSubmit" />
  </x-dialog>
</template>

<script>
import XAttachmentForm from '../XAttachmentForm/XAttachmentForm'
import repository from '../../supply-calendar.repository'

export default {
  name: 'XAttachmentModal',
  components: {
    XAttachmentForm
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  methods: {
    async handleSubmit (files) {
      for (const file of files) {
        const formData = new FormData()

        formData.append('file', file)
        formData.append('title', file.name)
        formData.append('sup_id', this.id)

        await repository.uploadAttachment(formData)
      }

      this.$dialog.hide()
    }
  }
}
</script>
