<template>
  <x-badge
    class="x-status"
    v-if="!isDone"
    :color="color"
  >
    {{ label }}
  </x-badge>
  <div
    v-else
    class="x-status x-status_done"
  >
    <x-icon
      size="xxs"
      name="check"
    />
    {{ label }}
  </div>
</template>

<script>
import { statuses } from '@/shared/constants/statuses'
import { getColor } from './shared'

export default {
  name: 'XStatus',
  props: {
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    isDone () {
      return this.label === statuses.DONE
    },
    color () {
      return getColor(this.label)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-status
    &_done
      display flex
      align-items center
      color: $colors.success
</style>
