var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-form',_vm._g({ref:"form",staticClass:"x-create-supply-details-form"},_vm.$listeners),[_c('p',{staticClass:"mt-0 mb-5 gray-darker--text"},[_vm._v(" Контрагент: "+_vm._s(_vm.agent)),_c('br'),_vm._v(" Склад: "+_vm._s(_vm.warehouse)),_c('br'),_vm._v(" Дата: "+_vm._s(_vm.date)+", "+_vm._s(_vm.period)),_c('br'),_vm._v(" Доступно паллет: "+_vm._s(_vm.pallets)+" (максимум "+_vm._s(_vm.goods)+" товаров) ")]),_c('x-heading',{staticClass:"mb-5",attrs:{"level":"5"}},[_vm._v(" Укажите детали поставки ")]),_c('x-row',{staticClass:"x-create-supply-details-form__details"},[_c('x-col',{staticClass:"x-create-supply-details-form__order",attrs:{"col":"6"}},[_c('x-row',{staticClass:"mb-3"},[_c('x-col',{attrs:{"col":"12"}},[_c('x-checkbox',{attrs:{"label":"В поставке паллеты"},model:{value:(_vm.hasPallets),callback:function ($$v) {_vm.hasPallets=$$v},expression:"hasPallets"}})],1)],1),(_vm.hasPallets)?_c('x-row',{staticClass:"mb-3"},[_c('x-col',{staticClass:"x-create-supply-details-form__link",attrs:{"col":"8"}},[_c('x-form-item',{attrs:{"dot":false,"rules":_vm.computedPalletsRules,"label":"Кол-во паллет","name":"Кол-во паллет","vid":"pallets_count"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('x-input',{attrs:{"failed":failed,"passed":passed,"placeholder":"Количество паллет"},model:{value:(_vm.model.pallets_count),callback:function ($$v) {_vm.$set(_vm.model, "pallets_count", _vm._n($$v))},expression:"model.pallets_count"}})]}}],null,false,2940825310)})],1),_c('x-col',{attrs:{"col":"4"}},[_c('x-form-item',{attrs:{"dot":false,"rules":_vm.computedVolumeRules,"label":"Куб. м.","name":"Куб. м.","vid":"pallets_overall_volume"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('x-input',{attrs:{"failed":failed,"passed":passed,"placeholder":"Куб. м."},model:{value:(_vm.model.pallets_overall_volume),callback:function ($$v) {_vm.$set(_vm.model, "pallets_overall_volume", $$v)},expression:"model.pallets_overall_volume"}})]}}],null,false,109261731)})],1)],1):_vm._e(),_c('x-row',{staticClass:"mb-3"},[_c('x-col',{attrs:{"col":"12"}},[_c('x-checkbox',{attrs:{"label":"В поставке коробки"},model:{value:(_vm.hasBoxes),callback:function ($$v) {_vm.hasBoxes=$$v},expression:"hasBoxes"}})],1)],1),(_vm.hasBoxes)?_c('x-row',{staticClass:"mb-3"},[_c('x-col',{staticClass:"x-create-supply-details-form__link",attrs:{"col":"8"}},[_c('x-form-item',{attrs:{"dot":false,"rules":_vm.computedBoxRules,"label":"Количество коробок","name":"Количество коробок","vid":"boxes_count"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('x-input',{attrs:{"failed":failed,"passed":passed,"placeholder":"Количество коробок"},model:{value:(_vm.model.boxes_count),callback:function ($$v) {_vm.$set(_vm.model, "boxes_count", _vm._n($$v))},expression:"model.boxes_count"}})]}}],null,false,1551617817)})],1),_c('x-col',{attrs:{"col":"4"}},[_c('x-form-item',{attrs:{"dot":false,"rules":_vm.computedVolumeRules,"label":"Куб. м.","name":"Куб. м.","vid":"boxes_overall_volume"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('x-input',{attrs:{"placeholder":"Куб. м.","failed":failed,"passed":passed},model:{value:(_vm.model.boxes_overall_volume),callback:function ($$v) {_vm.$set(_vm.model, "boxes_overall_volume", $$v)},expression:"model.boxes_overall_volume"}})]}}],null,false,3300389523)})],1)],1):_vm._e(),_c('x-row',{staticClass:"mb-3 mt-8"},[_c('x-col',{attrs:{"col":"12"}},[_c('x-form-item',{attrs:{"dot":false,"rules":_vm.computedGoodsRules,"label":"Кол-во товаров в поставке","name":"Кол-во товаров в поставке","vid":"goods_count"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('x-input',{attrs:{"disabled":!_vm.hasPallets && !_vm.hasBoxes,"failed":failed,"passed":passed,"placeholder":"Кол-во товаров в поставке"},model:{value:(_vm.model.goods_count),callback:function ($$v) {_vm.$set(_vm.model, "goods_count", _vm._n($$v))},expression:"model.goods_count"}})]}}])})],1)],1),_c('x-row',{staticClass:"mb-3"},[_c('x-col',{attrs:{"col":"12"}},[_c('x-checkbox',{attrs:{"label":"Товары с штрихкодом"},model:{value:(_vm.model.barcode),callback:function ($$v) {_vm.$set(_vm.model, "barcode", $$v)},expression:"model.barcode"}})],1)],1),_c('x-row',[_c('x-col',{attrs:{"col":"12"}},[_c('x-form-item',{attrs:{"dot":false,"hide-label":"","name":"Номенклатура","rules":"required","vid":"attachments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var passed = ref.passed;
return [_c('x-create-supply-file-uploader',_vm._g({attrs:{"value":_vm.model.attachments,"failed":failed,"passed":passed}},_vm.$listeners))]}}])})],1)],1)],1),_c('x-col',{staticClass:"x-create-supply-details-form__result",attrs:{"col":"6"}},[_c('x-row',{staticClass:"mb-3"},[_c('x-col',{attrs:{"col":"12"}},[_c('x-price-calculator',{attrs:{"model":_vm.model}})],1)],1)],1)],1),_c('x-row',[_c('x-col',{attrs:{"col":"6"}},[_c('x-btn',{attrs:{"type":"button","color":"gray","block":""},on:{"click":_vm.onHandleBackClick}},[_vm._v(" Назад ")])],1),_c('x-col',{attrs:{"col":"6"}},[_c('x-btn',{attrs:{"block":"","loading":_vm.isLoading,"disabled":_vm.computedDisabled}},[_vm._v(" Создать заявку ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }