import Vue from 'vue'
import { required, email, max_value as maxValue, integer } from 'vee-validate/dist/rules'
import { extend, ValidationObserver } from 'vee-validate'
import isNumber from '@/utils/number/isNumber'
import normalizeNumber from '@/utils/number/normalizeNumber'

extend('required', {
  ...required,
  message: 'Поле "{_field_}" обязательно'
})

extend('email', {
  ...email,
  message: 'Поле "{_field_}" не похоже на email'
})

extend('max_value', {
  ...maxValue,
  params: ['max'],
  message: 'Максимум {max}'
})

extend('integer', {
  ...integer,
  message: 'Поле "{_field_}" должно быть целым числом'
})

extend('isNumber', {
  validate: (value) => isNumber(value),
  message: 'Поле "{_field_}" должно быть числом'
})

extend('greaterThanZero', {
  message: field => `Поле "${field}" должно быть больше 0`,
  validate: (value) => parseFloat(normalizeNumber(value)) > 0
})

Vue.component('ValidationObserver', ValidationObserver)
