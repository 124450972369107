<template>
  <tbody class="grid__body">
    <template
      v-for="(cell, idx) in cells"
    >
      <tr
        :key="`${idx}-${cell.id}`"
        v-tooltip="{
          content: cell[errorDescriptionField],
          boundariesElement: 'window',
          classes: getTooltipClasses()
        }"
        class="grid__row"
      >
        <td
          v-if="selectable"
          class="grid__cell grid__cell_content_select grid__cell_freezed"
          :class="{ 'grid__cell_error': errors.includes(cell.id.toString())}"
        >
          <div class="grid__cell-in">
            <x-checkbox
              :model-value="checkedCellsIds"
              :value="cell[selectBy]"
              @change="$emit('cell-checked', cell)"
            />
          </div>
        </td>

        <td
          v-for="(column, index) in columns"
          :key="`${column.key}-${index}`"
          class="grid__cell"
          :class="getBodyClasses(column, index, cell)"
          @click="$emit('cell-clicked', {columns, cell})"
        >
          <div
            class="grid__cell-in"
            :data-cell="column.name"
          >
            <div
              v-if="column.type"
              :style="column.styles"
            >
              <slot
                :name="column.type"
                :[column.type]="cell[column.key]"
                :row="cell"
                :column="column"
                :row-index="idx"
                :visible="!freezed"
              />
            </div>

            <span
              v-else
              :style="column.styles"
            >
              {{ cell[column.key] }}
            </span>

            <x-icon
              v-if="cell.validation_errors && cell.validation_errors[column.key]"
              v-tooltip="{
                content: cell.validation_errors[column.key],
                boundariesElement: 'window',
                classes: getTooltipClasses()
              }"
              name="error-triangle"
              class="grid__cell-error-icon"
            />
          </div>
        </td>

        <td
          v-if="actions.length"
          class="grid__cell"
          :class="{
            'grid__cell_content_actions': showActions,
            'grid__action_freezed': freezed,
            'grid__cell_error': errors.includes(cell.id.toString())
          }"
        >
          <div class="grid__cell-in">
            <slot
              name="actions"
              :row="cell"
            >
              <grid-row-action-menu
                :actions="actions"
                :row="cell"
              />
            </slot>
          </div>
        </td>
      </tr>

      <tr
        v-if="unfoldable"
        :key="`unfoldable-${idx}-${cell.id}`"
        class="grid__row grid__row_unfoldable"
      >
        <td v-if="selectable" /> <!-- spacer for selectable + unfoldable grid -->
        <td
          :key="`unfoldable-${cell.key}-${idx}`"
          class="grid__cell grid__cell_unfoldable"
          :colspan="unfoldableColspan"
        >
          <!-- <transition-expand> -->
            <slot
              v-if="cell.isUnfold"
              name="unfold"
              :row="cell"
              :row-index="idx"
              :visible="!freezed"
            >
              <div class="grid__cell-in">
                <table
                  class="grid__table"
                  :class="{ 'auto': auto }"
                >
                  <grid-head
                    class="hide"
                    :sort-orders="sortOrders"
                    :sort-key="sortKey"
                    :columns="columns"
                  />

                  <tbody class="grid__body">
                    <tr
                      v-for="item in cell.unfold"
                      :key="item.id"
                    >
                      <td
                        v-for="(column, index) in columns"
                        :key="`${column.key}-${index}`"
                        class="grid__cell"
                        :class="getBodyClasses(column, index, cell)"
                        @click="$emit('cell-clicked', {columns, item})"
                      >
                        <div
                          class="grid__cell-in"
                          :data-cell="column.name"
                        >
                          <div v-if="column.type">
                            <slot
                              :name="column.type"
                              :[column.type]="item[column.key]"
                              :row="item"
                            />
                          </div>

                          <span v-else>{{ item[column.key] }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </slot>
          <!-- </transition-expand> -->
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
import GridHead from '../XGridHead/XGridHead'
import GridRowActionMenu from '../XGridRowActionMenu/XGridRowActionMenu'
// import TransitionExpand from '@/components/interface/XTransitionExpand/XTransitionExpand'

export default {
  components: {
    GridHead,
    GridRowActionMenu
    // TransitionExpand
  },

  props: {
    cells: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    sortOrders: {
      type: Object,
      default: () => ({})
    },
    sortKey: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    },
    auto: {
      type: Boolean,
      default: false
    },
    checkedCellsIds: {
      type: Array,
      default: () => []
    },
    selectBy: {
      type: String,
      default: 'id'
    },
    freezed: Boolean,
    unfoldable: Boolean,
    selectable: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    errorDescriptionField: {
      type: String,
      default: ''
    },
    showActions: Boolean
  },

  computed: {
    freezedColumnsCount () {
      return this.columns.filter(c => c.freezed).length
    },
    unfoldableColspan () {
      return this.actions.length ? this.columns.length + 1 : this.columns.length
    }
  },

  methods: {
    getBodyClasses (column, index, cell) {
      const classes = [{ 'hidden-on-mobile': column.hiddenOnMobile }]

      if (this.freezed) {
        classes.push(column.freezed ? 'grid__cell_freezed' : 'hidden')

        if (index === this.freezedColumnsCount - 1) {
          classes.push('grid__cell_frezed-last')
        }
      }

      if (this.errors.includes(cell.id.toString())) {
        classes.push('grid__cell_error')
      }

      if (column.styleType) {
        classes.push(`grid__cell_viewtype_${column.styleType}`)
      }

      return classes
    },

    getTooltipClasses () {
      return ['tooltip-primary_arrow-center', 'tooltip-primary', 'open', 'error']
    }
  }
}
</script>

<style lang="stylus">
  // INFO Hack for Firefox collapsing cells
  @-moz-document url-prefix()
    .grid__row
      height 100%!important

    .grid__cell
      height 100%!important
</style>

<style lang="stylus" scoped>
  .grid
    &__cell
      font-size 14px
      font-weight normal
      color #3c3c47
      border-top 1px solid #d7d6d6

      @media (max-width 768px)
        display block
        border-top none

      &:last-child
        @media (max-width 768px)
          border-bottom 1px solid #d7d6d6

      &.hidden
        opacity 0
        pointer-events none

      &_freezed
        background-color #fdfdfd
        pointer-events auto

      &_frezed-last
        .grid__cell-in
          position relative

          &:after
            content ""
            position absolute
            top 0
            bottom 0
            left 100%
            width 20px
            background linear-gradient(90deg, rgba(60, 60, 71, .08) 0%, rgba(60, 60, 71, 0) 100%)

      &_viewtype_numbers
        .grid__cell-in
          display flex
          justify-content flex-end
          text-align right
          font-family Arial
          font-size 13px

      &_content_actions
        pointer-events all

        .grid-container_freezed &
          background-color #fdfdfd!important

          .grid__cell-in
            position relative
            background-color #fdfdfd

            &:after
              content ""
              position absolute
              top 0
              bottom 0
              right 100%
              width 20px
              background linear-gradient(90deg, rgba(60, 60, 71, 0.08) 0%, rgba(60, 60, 71, 0) 100%)
              transform matrix(-1, 0, 0, 1, 0, 0)

      &_error
        background-color #fef5f5!important

      &_unfoldable
        border-top none

    &__cell-in
      display flex
      height 100%
      padding 10px
      box-sizing border-box

      @media (max-width 768px)
        position relative
        text-align right
        padding-left 40%

      &:before
        @media (max-width 768px)
          content attr(data-cell)
          position absolute
          top 50%
          left 0
          width 40%
          text-align left
          white-space pre
          transform translateY(-50%)

    &__cell-error-icon
      width 14px
      height 14px
      margin-left 5px
      fill #F03738

    &__action
      &_freezed
        position sticky
        right -1px
        background-color #fdfdfd
        border 0

    &__body
      .grid__row
        &:not(.grid__row_unfoldable)
          &:hover
            .grid__cell
              background-color #f2f2f4

    &__table
      width 100%
      table-layout fixed
      border-collapse collapse

      &.auto
        table-layout auto
</style>
