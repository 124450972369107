<template>
  <date-picker
    v-bind="computedAttrs"
    v-on="$listeners"
  />
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'XCalendar',
  inheritAttrs: false,
  components: {
    DatePicker
  },
  computed: {
    computedAttrs () {
      return {
        ...this.$attrs,
        color: 'indigo',
        locale: {
          masks: {
            weekdays: 'WW'
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus">
  .vc-container
    font-family $font-family
    border none
    border-radius 0

  .vc-pane,
  .vc-weeks
    min-width 196px!important

  .vc-header
    padding 14px 18px 10px 18px

  .vc-title
    text-transform capitalize
    font-size 16px
    line-height 20px
    color: $colors.gray-darkest-2
    font-weight: $font-weights.semibold

  .vc-weekday
    color: $colors.gray-dark
    font-weight: $font-weights.normal
    font-size 12px
    text-transform uppercase

  .vc-day-content
    --font-bold: $font-weights.normal
    --indigo-900: $colors.primary
    --rounded-full $space

    &.is-disabled
      pointer-events none

  .vc-highlight
    --rounded-full $space
    --indigo-600: $colors.primary
    --indigo-200: $colors.primary-lightest-2
</style>
