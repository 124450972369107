<template>
  <x-page
    card
    title="Календарь поставок на склад XWAY"
    class="x-supply-calendar"
  >
    <x-heading
      level="5"
      class="mb-5"
    >
      Заявки на поставку
    </x-heading>

    <x-supply-calendar-filter
      v-if="!isLoading"
      class="mb-5"
      v-bind="filterProps"
      @clear="handleClearFilters"
    />

    <x-supply-calendar-filter-status-bar
      class="mb-3"
      v-bind="statusProps"
      @change="value => expandAllSupplies = value"
    />

    <x-supply-calendar-list v-bind="listProps" />

  </x-page>
</template>

<script>
import { formatDate } from '@/helpers/dates'
import XGet from '@/utils/object/XGet'
import XIsEqual from '@/utils/object/XIsEqual'
import XHeading from '@/components/common/XHeading/XHeading'
import XSupplyCalendarFilter from './components/XSupplyCalendarFilter/XSupplyCalendarFilter'
import XSupplyCalendarFilterStatusBar from './components/XSupplyCalendarFilterStatusBar/XSupplyCalendarFilterStatusBar'
import XSupplyCalendarList from './components/XSupplyCalendarList/XSupplyCalendarList'
import XCreateSupplyModal from './components/XCreateSupplyModal/XCreateSupplyModal'
import XCancelSupplyConfirmModal from './components/XCancelSupplyConfirmModal/XCancelSupplyConfirmModal'
import XSupplyInfoModal from './components/XSupplyInfoModal/XSupplyInfoModal'
import { getDefaultFiltersState } from './shared'
import { ymGoals, sendYmGoal } from '../../shared'
import fields from './fields'
import repository from './supply-calendar.repository'

import { defaultAllOption, defaultAllId } from '@/app.shared'

export default {
  name: 'XSupplyCalendar',
  provide () {
    return {
      handleSupplyCreate: this.handleSupplyCreate,
      handleSupplyCancel: this.handleSupplyCancel,
      handleShowSupplyInfo: this.handleShowSupplyInfo
    }
  },
  components: {
    XHeading,
    XSupplyCalendarFilter,
    XSupplyCalendarFilterStatusBar,
    XSupplyCalendarList
  },
  data () {
    return {
      isLoading: false,
      isListLoading: false,
      count: 0,
      data: [],
      slotsPeriods: [],
      fields,
      model: getDefaultFiltersState(),
      expandAllSupplies: false,
      isResetDisabled: true
    }
  },
  watch: {
    expandAllSupplies (value) {
      this.data = this.data.map(d => ({
        ...d,
        isUnfold: value
      }))
    },
    model: {
      deep: true,
      handler (value) {
        this.isResetDisabled = XIsEqual(value, getDefaultFiltersState())

        this.fetchData()
      }
    }
  },
  computed: {
    statusProps () {
      return {
        isLoading: this.isLoading,
        count: this.count,
        expand: this.expandAllSupplies
      }
    },
    listProps () {
      return {
        isLoading: this.isLoading || this.isListLoading,
        data: this.data,
        count: this.count
      }
    },
    computedFieldsProps () {
      return {
        ...this.fields,
        agents: {
          ...this.fields.agents,
          props: {
            ...this.fields.agents.props,
            options: [
              defaultAllOption,
              ...this.fields.agents.props.options
            ]
          }
        }
      }
    },
    filterProps () {
      return {
        model: this.model,
        fields: this.computedFieldsProps,
        disabled: this.isLoading,
        disableReset: this.isResetDisabled
      }
    }
  },
  async mounted () {
    this.isLoading = true
    await Promise.all([this.fetchData(), this.getFormData()])
    this.isLoading = false
  },
  methods: {
    prepareFilters () {
      const { agents, status, id, date: { start, end } } = this.model

      return {
        agent: agents.id !== defaultAllId ? agents.id : null,
        status: status.id !== defaultAllId ? status.name : null,
        from_date: start ? formatDate(start, 'YYYY-MM-DD') : null,
        until_date: end ? formatDate(end, 'YYYY-MM-DD') : null,
        sup_id: id || null
      }
    },
    async fetchData () {
      try {
        this.isListLoading = true

        const params = this.prepareFilters()

        const response = await repository.getSupplies(params)

        this.data = XGet(response, 'data.results', [])

        this.count = XGet(response, 'data.count', 0)
      } finally {
        this.isListLoading = false
      }
    },
    async getFormData () {
      try {
        const response = await repository.getFormData()

        this.slotsPeriods = XGet(response, 'data.day_period', [])

        this.fields.agents.props.options = XGet(response, 'data.agents', [])

        this.fields.warehouses.props.options = XGet(response, 'data.warehouses', [])
      } catch (e) {
        return false
      }
    },
    async handleCancelSupply ({ id, data }) {
      try {
        this.handleCloseConfirmModal()

        await repository.cancelSupply(id, data)

        this.$toast.success('Заявка отменена')

        this.fetchData()
      } catch (e) {
        return false
      }
    },
    handleSupplyCreate () {
      sendYmGoal(ymGoals.clickNewRequest)

      this.$dialog.show(XCreateSupplyModal, {
        props: () => ({
          periods: this.slotsPeriods,
          fields: this.fields
        }),
        on: {
          success: () => this.fetchData()
        }
      })
    },
    handleSupplyCancel (id) {
      this.$dialog.show(XCancelSupplyConfirmModal, {
        props: () => ({ id }),
        on: {
          'action-confirm': () => this.handleCancelSupply(),
          'action-cancel': () => this.handleCloseConfirmModal()
        }
      })
    },
    handleShowSupplyInfo (id) {
      this.$dialog.show(XSupplyInfoModal, {
        props: () => ({ id })
      })
    },
    handleCloseConfirmModal () {
      this.$dialog.hide()
    },
    handleClearFilters () {
      this.model = getDefaultFiltersState()
    }
  }
}
</script>
