<template>
  <x-form
    ref="form"
    class="x-create-supply-date-form"
  >
    <x-heading
      class="mb-5"
      level="5"
    >
      Выберите контрагента, склад, дату и время поставки
    </x-heading>

    <x-row class="mb-5">
      <x-col
        class="x-create-supply-date-form__calendar"
        auto
      >
        <x-form-item
          :dot="false"
          class="mb-5"
          label="Контрагент"
          vid="seller"
          rules="required"
          v-slot="{ failed, passed }"
        >
          <x-select
            v-model="model.agent"
            placeholder="Контрагент"
            label="name"
            v-bind="fields.agents.props"
            :failed="failed"
            :passed="passed"
          />
        </x-form-item>

        <x-row class="mb-3">
          <x-col col="12">
            <x-form-item
              :dot="false"
              label="Склад"
              vid="wh"
              rules="required"
              v-slot="{ failed, passed }"
            >
              <x-select
                v-model="model.warehouse"
                placeholder="Склад"
                label="name"
                v-bind="fields.warehouses.props"
                :failed="failed"
                :passed="passed"
              />
            </x-form-item>
          </x-col>
        </x-row>

        <div class="mb-5 mt-3">
          <x-calendar
            v-model="model.estimated_delivery_date"
            :min-date="startDate"
            :columns="2"
            is-expanded
            trim-weeks
          />
        </div>
      </x-col>

      <x-col
        class="x-create-supply-date-form__slots"
        auto
      >
        <template v-if="date">
          <x-form-item
            :dot="false"
            class="mb-3"
            label="Время поставки на"
          >
            <div class="x-create-supply-date-form__date">
              {{ supplyDate }}
            </div>
          </x-form-item>

          <x-time-slots-list
            v-model="model.day_period"
            v-bind="slotsProps"
          />
        </template>
      </x-col>
    </x-row>

    <x-row>
      <x-col col="6">
        <x-btn
          type="button"
          color="gray"
          block
          @click="onHandleCancelClick"
        >
          Отменить
        </x-btn>
      </x-col>

      <x-col col="6">
        <x-btn
          type="button"
          block
          :disabled="isDisable"
          @click="onHandleNextClick"
        >
          Далее
        </x-btn>
      </x-col>
    </x-row>
  </x-form>
</template>

<script>
import { ESTIMATED_DATE_OFFSET } from '../../shared'
import { formatDate, addDays } from '@/helpers/dates'
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'
import XForm from '@/components/interface/XForm/XForm'
import XHeading from '@/components/common/XHeading/XHeading'
import XCalendar from '@/components/interface/XCalendar/XCalendar'
import XTimeSlotsList from '@/components/interface/XTimeSlotsList/XTimeSlotsList'

export default {
  name: 'XCreateSupplyDateForm',
  components: {
    XRow,
    XCol,
    XForm,
    XHeading,
    XCalendar,
    XTimeSlotsList
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    slots: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    isLoading: Boolean
  },
  data () {
    return {
      startDate: addDays(new Date(), ESTIMATED_DATE_OFFSET)
    }
  },
  computed: {
    date () {
      return this.model.estimated_delivery_date
    },
    warehouse () {
      return this.model.warehouse
    },
    period () {
      return this.model.day_period
    },
    agent () {
      return this.model.agent && this.model.agent.id
    },
    supplyDate () {
      if (!this.date) return null

      return formatDate(this.date, 'DD MMMM YYYY')
    },
    slotsProps () {
      return {
        data: this.slots,
        isLoading: this.isLoading
      }
    },
    isDisable () {
      return !this.date || !this.period || !this.agent
    }
  },
  watch: {
    date (date) {
      if (date) {
        this.handleFetchSlots()
      }
    },
    warehouse () {
      this.handleFetchSlots()
    },
    errors (value) {
      this.$refs.form.setErrors(value)
    }
  },
  mounted () {
    this.handleFetchSlots()
  },
  methods: {
    onHandleCancelClick () {
      this.$emit('cancel')
    },
    onHandleNextClick () {
      this.$emit('next')
    },
    handleFetchSlots () {
      this.$emit('fetch-slots')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-create-supply-date-form
    font-size 14px

    &__date
      font-size $font-size-m
      font-weight $font-weights.semibold

    &__calendar
      padding-right 6 * $space

    &__slots
      padding-left 5 * $space
      border-left: 1px solid $colors.gray-light
</style>
