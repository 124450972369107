<template>
  <x-dialog
    title="Отменить заявку?"
    no-actions
    v-on="$listeners"
  >
    <x-cancel-supply-form
      id="cancel-supply-form"
      :model="model"
      @submit="handleSubmit"
    />

    <x-row class="mt-5">
      <x-col col="6">
        <x-btn
          type="button"
          color="gray"
          block
          @click="$emit('action-cancel')"
        >
          Нет, оставить
        </x-btn>
      </x-col>
      <x-col col="6">
        <x-btn
          form="cancel-supply-form"
          block
        >
          Да, отменить
        </x-btn>
      </x-col>
    </x-row>
  </x-dialog>
</template>

<script>
import XCancelSupplyForm from './components/XCancelSupplyForm/XCancelSupplyForm'
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'
import { SELF_REASON, getReasonByType } from './shared'

export default {
  name: 'XCancelSupplyConfirmModal',
  components: {
    XCancelSupplyForm,
    XRow,
    XCol
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      model: {
        reason: '',
        selfReason: ''
      },
      defaultModel: {}
    }
  },
  computed: {
    reasonText () {
      const { reason, selfReason } = this.model

      if (reason === SELF_REASON) return selfReason

      return getReasonByType(reason)
    }
  },
  created () {
    this.defaultModel = { ...this.model }
  },
  methods: {
    handleSubmit () {
      this.$emit('action-confirm', {
        id: this.id,
        data: {
          reason: this.reasonText
        }
      })
    }
  }
}
</script>
