<template>
  <label :class="computedClasses">
    <input
      class="visually-hidden"
      type="radio"
      name="slots"
      :checked="shouldBeChecked"
      :value="selfValue"
      @change="onChange"
    />
    <span class="x-time-slot__in">
      <span class="x-time-slot__title">
        {{ data.title }}
      </span>

      <span class="x-time-slot__meta">
        доступно {{ data.pallets }} паллет
      </span>
    </span>

    <span
      v-if="shouldBeChecked"
      class="x-time-slot__footer mt-1"
    >
      Макс. товаров: {{ data.goods }} шт.
    </span>
  </label>
</template>

<script>
export default {
  name: 'XTimeSlot',
  props: {
    value: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    selfValue () {
      return this.data.title
    },
    shouldBeChecked () {
      return this.value === this.selfValue
    },
    computedClasses () {
      return [
        'x-time-slot',
        { 'x-time-slot--active': this.shouldBeChecked }
      ]
    }
  },
  methods: {
    onChange () {
      this.$emit('change', this.data.title)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-time-slot
    position relative
    display block
    cursor pointer
    user-select none

    &--active &__in
      background-color: $colors.primary
      color: $colors.white
      cursor default

    &__in
      display block
      padding 2 * $space
      border: 1px solid $colors.primary
      border-radius $radius

    &__title
      display block
      font-size $font-size-m
      font-weight $font-weights.semibold

    &__meta
      display block
      font-size: $font-sizes.caption
      color $gray-darker

    &__footer
      display block
      font-size: $font-sizes.caption
      color: $colors.gray-darkest-2
</style>
