<template>
  <x-dialog
    size="m"
    title="Информация по поставке"
    no-actions
  >
    <x-loader v-if="isLoading" />
    <x-supply-info
      v-else
      :model="data"
      @close="$dialog.hide()"
    />
  </x-dialog>
</template>

<script>
import XSupplyInfo from '../XSupplyInfo/XSupplyInfo'
import repository from '../../supply-calendar.repository'

export default {
  name: 'XSupplyInfoModal',
  components: {
    XSupplyInfo
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      data: {},
      isLoading: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        this.isLoading = true

        const { data } = await repository.getSupplyInfo(this.id)

        this.data = data
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
