import { defaultAllOption } from '@/app.shared'

export const defaultSelectProps = {
  label: 'name',
  trackBy: 'id'
}

export const getDefaultFiltersState = () => ({
  agents: defaultAllOption,
  status: defaultAllOption,
  date: {
    start: null,
    end: null
  },
  id: ''
})
