<template>
  <x-form class="x-products-export-form">
    <x-row class="align-end">
      <x-col col="2">
       <x-form-item
          :dot="false"
          label="Контрагент"
          name="Контрагент"
          vid="seller"
          rules="required"
          v-slot="{ failed, passed }"
        >
          <x-select
            v-model="model.agents"
            placeholder="Не выбрано"
            label="name"
            v-bind="fields.agents.props"
            :failed="failed"
            :passed="passed"
          />
        </x-form-item>
      </x-col>

      <x-col>
        <x-btn prepend="upload">
          Выгрузить в Excel
        </x-btn>
      </x-col>
    </x-row>
  </x-form>
</template>

<script>
import XForm from '@/components/interface/XForm/XForm'
import XRow from '@/components/common/XRow/XRow'
import XCol from '@/components/common/XCol/XCol'

import fields from './fields'

import { createModelFromFields } from '../../../../app.shared'

export default {
  name: 'XProductsExportForm',
  components: {
    XForm,
    XRow,
    XCol
  },
  data () {
    return {
      fields,
      model: {}
    }
  },
  created () {
    this.model = createModelFromFields(this.fields)
  }
}
</script>

<style lang="stylus" scoped>
.x-products-export-form
  >>>.x-form-item
    position relative

    .x-form-item__errors
      position absolute
      top 100%
</style>
