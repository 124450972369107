export default [{
  name: 'ID заявки',
  key: 'id',
  type: 'id',
  unfoldable: true
}, {
  name: 'Статус',
  key: 'status',
  type: 'status'
}, {
  name: 'Дата поставки',
  key: 'estimated_delivery_date',
  type: 'deliveryDate'
}, {
  name: 'Кол-во паллет, шт',
  key: 'pallets_count'
}, {
  name: 'Стоимость, руб.',
  key: 'estimated_cost'
}, {
  name: '',
  type: 'actions',
  styles: 'width: 100%'
}]
