<template>
  <x-dialog
    :title="computedTitle"
    no-actions
    size="m"
  >
    <component
      :is="component"
      v-bind="componentProps"
      @fetch-slots="fetchSlots"
      @upload-attachment="uploadAttachment"
      @delete-attachment="deleteAttachment"
      @cancel="handleCancelClick"
      @next="handleNextClick"
      @back="handleBackClick"
      @submit="handleSubmit"
      @close="handleCancelClick"
    />
  </x-dialog>
</template>

<script>
import { formatDate } from '@/helpers/dates'
import XGet from '@/utils/object/XGet'
import XCreateSupplyDateForm from './components/XCreateSupplyDateForm/XCreateSupplyDateForm'
import XCreateSupplyDetailsForm from './components/XCreateSupplyDetailsForm/XCreateSupplyDetailsForm'
import XSupplyInfo from '../XSupplyInfo/XSupplyInfo'
import repository from '../../supply-calendar.repository'
import { getDefaultModel } from './shared'
import normalizeNumber from '@/utils/number/normalizeNumber'
import { ymGoals, sendYmGoal } from '../../../../shared'

export default {
  name: 'XCreateSupplyModal',
  components: {
    XCreateSupplyDateForm,
    XCreateSupplyDetailsForm,
    XSupplyInfo
  },
  props: {
    periods: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      name: 'x-create-supply-modal',
      isLoading: false,
      isDisabled: false,
      step: 0,
      slots: [],
      model: getDefaultModel(),
      errors: null
    }
  },
  computed: {
    computedTitle () {
      if (this.step > 1) return 'Поставка успешно назначена'

      return `Создание заявки на поставку товаров на склад XWAY ( Шаг ${this.step + 1}/2 )`
    },
    component () {
      switch (this.step) {
        case 1:
          return XCreateSupplyDetailsForm.name

        case 2:
          return XSupplyInfo.name

        default:
          return XCreateSupplyDateForm.name
      }
    },
    componentProps () {
      return {
        periods: this.periods,
        model: this.model,
        fields: this.fields,
        slots: this.slots,
        isLoading: this.isLoading,
        isDisabled: this.isDisabled,
        errors: this.errors
      }
    }
  },
  methods: {
    handleCancelClick () {
      this.$dialog.hide()
    },
    handleNextClick () {
      sendYmGoal(ymGoals.clickCreateNewRequestNextStep)

      this.step++
    },
    handleBackClick () {
      if (!this.step) return

      this.step--
    },
    async fetchSlots () {
      try {
        const {
          estimated_delivery_date: date,
          warehouse: { id: wh }
        } = this.model

        this.errors = []

        this.isLoading = true

        const response = await repository.getWarehouseAbility({
          date: formatDate(date, 'YYYY-MM-DD'),
          wh
        })

        const slots = XGet(response, 'data', {})

        this.slots = Object
          .keys(this.periods)
          .map(key => ({
            title: this.periods[key],
            ...slots[key]
          }))
          .filter(slot => slot.pallets)

        this.model.day_period = this.slots[0].title
      } catch (err) {
        this.errors = err
      } finally {
        this.isLoading = false
      }
    },
    uploadAttachment (file) {
      this.model.attachments.push(file)
    },
    deleteAttachment (id) {
      const { attachments } = this.model

      const index = attachments.findIndex(a => a.id === id)

      if (index !== -1) {
        attachments.splice(index, 1)
      }
    },
    prepareModel () {
      const {
        estimated_delivery_date: date,
        attachments,
        pallets_count: pallets,
        boxes_count: boxes,
        boxes_overall_volume: volume,
        agent,
        warehouse,
        pallets_overall_volume: palletsVolume
      } = this.model

      return {
        ...this.model,
        estimated_delivery_date: formatDate(date, 'YYYY-MM-DD'),
        attachments: attachments.map(a => a.id),
        pallets_count: pallets || 0,
        boxes_count: boxes || 0,
        boxes_overall_volume: normalizeNumber(volume) || 0,
        seller: agent.id,
        warehouse: warehouse.id,
        pallets_overall_volume: normalizeNumber(palletsVolume) || 0
      }
    },
    async handleSubmit () {
      try {
        this.errors = []

        this.isDisabled = true

        const model = this.prepareModel()

        const { data } = await repository.createSupply(model)

        this.model.warehouse.map = data.wh_map

        this.step++

        sendYmGoal(ymGoals.clickCreateNewRequest)

        this.$emit('success')
      } catch (err) {
        this.errors = err
      } finally {
        this.isDisabled = false
      }
    }
  }
}
</script>
