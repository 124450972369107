export const statuses = {
  ACCEPTED: 'Подтверждено',
  DIVERGENCE: 'Расхождение',
  CONSIDER: 'На рассмотрении',
  CANCEL_BY_SELLER: 'Отменено селлером',
  CANCEL_BY_WAREHOUSE: 'Отменено складом',
  DONE: 'Выполнено'
}

export const statusesKeys = Object.keys(statuses).reduce((acc, key) => ({ ...acc, [statuses[key]]: key }), {})

export const statusFiltersOptions = Object.keys(statuses).reduce((acc, key) => {
  acc.push({
    id: statusesKeys[statuses[key]],
    name: statuses[key]
  })

  return acc
}, [])
