<template>
  <x-popover
    class="x-date-picker"
    popoverClass="x-date-picker__popover"
    popoverInnerClass="x-date-picker__inner-popover"
  >
    <x-input
      :value="inputText"
      :placeholder="placeholder"
      :disabled="disabled"
      prepend-icon="calendar"
      readonly
    />
    <template #popover>
      <x-calendar
        :value="value"
        :columns="2"
        :model-config="modelConfig"
        is-range
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
  </x-popover>
</template>

<script>
import { formatDate } from '@/helpers/dates'
import XCalendar from '../XCalendar/XCalendar'

export default {
  components: {
    XCalendar
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: Boolean
  },
  data: () => ({
    modelConfig: {
      type: 'number'
    }
  }),
  computed: {
    inputText () {
      if (!this.value.start && !this.value.end) return

      const start = formatDate(this.value.start)
      const end = formatDate(this.value.end)

      if (start === end) return start

      return start + ' – ' + end
    }
  },
  methods: {
    onInput (e) {
      this.$emit('input', e)
    }
  }
}
</script>
