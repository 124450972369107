import axios from '@/plugins/axios'
import { BASE } from '@/shared/api'

export default {
  async getSupplies (params) {
    return await axios.get(`${BASE}/supply-list-cr`, { params })
  },

  async getWarehouseAbility (params) {
    return await axios.get(`${BASE}/daily-availability`, { params })
  },

  async getFormData (params) {
    return await axios.get(`${BASE}/supply-form-data`, { params })
  },

  async uploadAttachment (formData) {
    return await axios.post(`${BASE}/upload-attachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  async deleteAttachment (id) {
    return await axios.delete(`${BASE}/delete-attachment/${id}`)
  },

  async createSupply (model) {
    return await axios.post(`${BASE}/supply-list-cr`, model)
  },

  async cancelSupply (id, data) {
    return await axios.patch(`${BASE}/cancel-sup-rq/${id}`, data)
  },

  async getSupplyInfo (id) {
    return await axios.get(`${BASE}/supply-detail/${id}`)
  }
}
