<template>
  <x-checkbox
    v-model="selfValue"
    v-bind="$attrs"
    class="x-switch"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'XSwitch',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selfValue: this.value
    }
  },
  watch: {
    value (value) {
      this.selfValue = value
    }
  }
}
</script>

<style lang="stylus">
  .x-switch
    &.x-checkbox
      &--size-xxs
        .x-checkbox-icon
          width 28px
          height 16px

          &:after
            width 12px
            height 12px

      &--size-xxs&--pressed:not(.x-checkbox--checked)
        .x-checkbox-icon
          &:after
            width 14px

      &--size-xs
        .x-checkbox-icon
          width 36px
          height 20px

          &:after
            width 16px
            height 16px

      &--size-xs&--pressed:not(.x-checkbox--checked)
        .x-checkbox-icon
          &:after
            width 20px

      &--size-s
        .x-checkbox-icon
          width 52px
          height 28px

          &:after
            width 24px
            height 24px

      &--size-s&--pressed:not(.x-checkbox--checked)
        .x-checkbox-icon
          &:after
            width 30px

      &--hovered,
      &--pressed
        .x-checkbox-icon
          background-color: $colors.primary-light

      &--hovered&--checked
        .x-checkbox-icon
          background-color: $colors.primary

      &--checked
        .x-checkbox-icon
          &:after
            transform translate(calc(100% - 1px), -50%)

    .x-checkbox-icon
      position relative
      background-color: $colors.gray
      border-radius 4 * $radius

      &:after
        content ""
        position absolute
        top 50%
        left 2px
        background-color: $colors.white
        border-radius 50%
        transform translateY(-50%)
        box-shadow 0 3px 1px 0 rgba(0, 0, 0, 0.06), 0 3px 8px 0 rgba(0, 0, 0, 0.15)
        transition transform .3s

    .x-checkbox-icon
      svg
        display none
</style>
