<template>
  <ul class="x-radio-group">
    <li
      v-for="field in fields"
      :key="field.id"
      class="x-radio-group__item"
    >
      <x-radio
        v-model="selfValue"
        :value="field.value"
        @change="onChange"
      >
        {{ field.title }}
      </x-radio>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'XRadioGroup',
  model: {
    event: 'change'
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: 'radiogroup'
    }
  },
  data () {
    return {
      selfValue: this.value
    }
  },
  watch: {
    value () {
      this.selfValue = this.value
    }
  },
  methods: {
    onChange (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-radio-group
    margin 0
    padding 0
    list-style none

    &__item
      margin-bottom $space * 3
</style>
