import '@xway-team/ui/dist/index.css'
import Vue from 'vue'
import UI, { registerComponents, dialogPlugin } from '@xway-team/ui'

Vue.use(UI, {
  breadcrumbsBase: {
    to: '/',
    text: 'Фулфилмент'
  }
})

registerComponents(Vue)

Vue.use(dialogPlugin)
