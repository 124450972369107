export default [{
  name: 'Дата',
  key: 'created_at',
  type: 'date',
  width: 150
}, {
  name: 'Документ',
  key: 'title',
  width: 150
}, {
  name: 'Загрузил',
  key: 'author',
  width: 150
}, {
  name: 'Скачать',
  key: 'file',
  type: 'download',
  width: 150
}, {
  name: '',
  key: 'spacer'
}]
