var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{staticClass:"grid__body"},[_vm._l((_vm.cells),function(cell,idx){return [_c('tr',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: cell[_vm.errorDescriptionField],
        boundariesElement: 'window',
        classes: _vm.getTooltipClasses()
      }),expression:"{\n        content: cell[errorDescriptionField],\n        boundariesElement: 'window',\n        classes: getTooltipClasses()\n      }"}],key:(idx + "-" + (cell.id)),staticClass:"grid__row"},[(_vm.selectable)?_c('td',{staticClass:"grid__cell grid__cell_content_select grid__cell_freezed",class:{ 'grid__cell_error': _vm.errors.includes(cell.id.toString())}},[_c('div',{staticClass:"grid__cell-in"},[_c('x-checkbox',{attrs:{"model-value":_vm.checkedCellsIds,"value":cell[_vm.selectBy]},on:{"change":function($event){return _vm.$emit('cell-checked', cell)}}})],1)]):_vm._e(),_vm._l((_vm.columns),function(column,index){return _c('td',{key:((column.key) + "-" + index),staticClass:"grid__cell",class:_vm.getBodyClasses(column, index, cell),on:{"click":function($event){return _vm.$emit('cell-clicked', {columns: _vm.columns, cell: cell})}}},[_c('div',{staticClass:"grid__cell-in",attrs:{"data-cell":column.name}},[(column.type)?_c('div',{style:(column.styles)},[_vm._t(column.type,null,_vm._d({"row":cell,"column":column,"rowIndex":idx,"visible":!_vm.freezed},[column.type,cell[column.key]]))],2):_c('span',{style:(column.styles)},[_vm._v(" "+_vm._s(cell[column.key])+" ")]),(cell.validation_errors && cell.validation_errors[column.key])?_c('x-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: cell.validation_errors[column.key],
              boundariesElement: 'window',
              classes: _vm.getTooltipClasses()
            }),expression:"{\n              content: cell.validation_errors[column.key],\n              boundariesElement: 'window',\n              classes: getTooltipClasses()\n            }"}],staticClass:"grid__cell-error-icon",attrs:{"name":"error-triangle"}}):_vm._e()],1)])}),(_vm.actions.length)?_c('td',{staticClass:"grid__cell",class:{
          'grid__cell_content_actions': _vm.showActions,
          'grid__action_freezed': _vm.freezed,
          'grid__cell_error': _vm.errors.includes(cell.id.toString())
        }},[_c('div',{staticClass:"grid__cell-in"},[_vm._t("actions",function(){return [_c('grid-row-action-menu',{attrs:{"actions":_vm.actions,"row":cell}})]},{"row":cell})],2)]):_vm._e()],2),(_vm.unfoldable)?_c('tr',{key:("unfoldable-" + idx + "-" + (cell.id)),staticClass:"grid__row grid__row_unfoldable"},[(_vm.selectable)?_c('td'):_vm._e(),_vm._v(" "),_c('td',{key:("unfoldable-" + (cell.key) + "-" + idx),staticClass:"grid__cell grid__cell_unfoldable",attrs:{"colspan":_vm.unfoldableColspan}},[(cell.isUnfold)?_vm._t("unfold",function(){return [_c('div',{staticClass:"grid__cell-in"},[_c('table',{staticClass:"grid__table",class:{ 'auto': _vm.auto }},[_c('grid-head',{staticClass:"hide",attrs:{"sort-orders":_vm.sortOrders,"sort-key":_vm.sortKey,"columns":_vm.columns}}),_c('tbody',{staticClass:"grid__body"},_vm._l((cell.unfold),function(item){return _c('tr',{key:item.id},_vm._l((_vm.columns),function(column,index){return _c('td',{key:((column.key) + "-" + index),staticClass:"grid__cell",class:_vm.getBodyClasses(column, index, cell),on:{"click":function($event){return _vm.$emit('cell-clicked', {columns: _vm.columns, item: item})}}},[_c('div',{staticClass:"grid__cell-in",attrs:{"data-cell":column.name}},[(column.type)?_c('div',[_vm._t(column.type,null,_vm._d({"row":item},[column.type,item[column.key]]))],2):_c('span',[_vm._v(_vm._s(item[column.key]))])])])}),0)}),0)],1)])]},{"row":cell,"rowIndex":idx,"visible":!_vm.freezed}):_vm._e()],2)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }