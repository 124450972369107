export const defaultAllId = 'all'

export const defaultAllOption = {
  name: 'Все',
  id: defaultAllId
}

export const createModelFromFields = fields => Object.keys(fields).reduce((acc, key) => ({
  ...acc,
  [fields[key].id]: fields[key].value ?? null
}))
