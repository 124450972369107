<template>
  <div class="x-supply-calendar-list">
    <x-loader v-if="isLoading" />
    <template v-else>
      <div v-if="data.length">
        <x-grid
          auto
          unfoldable
          :columns="columns"
          :cells="data"
        >
          <template #id="{ row }">
            <div>
              {{ row.id }}

              <x-unfold-button
                v-if="row.supply_attachments && row.supply_attachments.length"
                :active="row.isUnfold"
                @click="$set(row, 'isUnfold', !row.isUnfold)"
              />
            </div>
            <div class="caption gray-darker--text">{{ row.seller_name }}</div>
          </template>
          <template #status="{ status, row }">
            <div>
              <x-status :label="status" />
            </div>

            <div v-if="status === statuses.DIVERGENCE">
              <x-btn
                class="x-supply-calendar-list__attachment"
                prepend="attachment"
                size="xs"
                ghost
                @click="handleShowAttachmentModal(row.id)"
              >
                Прикрепить ТОРГ-12 или УПД
              </x-btn>
            </div>
          </template>
          <template #deliveryDate="{ deliveryDate, row: { day_period } }">
            {{ formatDate(deliveryDate) }}, {{ formatPeriod(day_period) }}
          </template>
          <template #unfold="{ row: { supply_attachments } }">
            <x-attachments-list
              v-if="supply_attachments && supply_attachments.length"
              :data="supply_attachments"
            />
          </template>
          <template #actions="{ row: supply }">
            <div class="text-right">
              <x-btn
                v-tooltip="{ content: 'Информация по поставке' }"
                color="gray"
                size="xs"
                icon="list"
                ghost
                @click="handleShowSupplyInfo(supply.id)"
              />
              <x-btn
                v-if="isShowCancelButton(supply.status)"
                v-tooltip="{ content: 'Отменить заявку' }"
                color="gray"
                size="xs"
                icon="x"
                ghost
                @click="handleSupplyCancel(supply.id)"
              />
            </div>
          </template>
        </x-grid>
      </div>
      <x-supply-calendar-placeholder v-else />
    </template>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/dates'
import XGrid from '@/components/interface/XGrid/XGrid'
import XStatus from '@/components/common/XStatus/XStatus'
import XUnfoldButton from '@/components/interface/XUnfoldButton/XUnfoldButton'
import { statuses } from '@/shared/constants/statuses'
import XAttachmentsList from '../XAttachmentsList/XAttachmentsList'
import XSupplyCalendarPlaceholder from '../XSupplyCalendarPlaceholder/XSupplyCalendarPlaceholder'
import XAttachmentModal from '../XAttachmentModal/XAttachmentModal'
import columns from './columns'

export default {
  name: 'XSupplyCalendarList',
  inject: ['handleSupplyCreate', 'handleSupplyCancel', 'handleShowSupplyInfo'],
  components: {
    XGrid,
    XStatus,
    XUnfoldButton,
    XAttachmentsList,
    XSupplyCalendarPlaceholder
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isLoading: Boolean
  },
  data () {
    return {
      columns,
      statuses
    }
  },
  methods: {
    formatDate,
    formatPeriod (period) {
      return period.split(' ')[0].toLowerCase()
    },
    isShowCancelButton (status) {
      const canCancelledStatuses = [statuses.CONSIDER, statuses.ACCEPTED]

      return canCancelledStatuses.includes(status)
    },
    handleShowAttachmentModal (id) {
      this.$dialog.show(XAttachmentModal, {
        props: () => ({ id })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-supply-calendar-list
    &__attachment
      margin-top $space
      margin-left 1 - 2 * $space
      color: $colors.primary
</style>
