<template>
  <x-dialog v-bind="computedProps">
    {{ message }}
  </x-dialog>
</template>

<script>
export default {
  name: 'XConfirm',
  props: {
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    }
  },
  computed: {
    computedProps () {
      return {
        title: this.title,
        noSubmit: true,
        centerTitle: true,
        cancelText: 'Замечательно',
        cancelProps: {
          color: 'primary'
        }
      }
    }
  }
}
</script>
