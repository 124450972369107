<template>
  <component
    :is="tag"
    class="x-bullet-list"
  >
    <li
      v-for="(item, idx) in items"
      :key="idx"
      class="x-bullet-list__item mb-4"
    >
      <x-icon
        v-if="icon"
        :name="icon"
        :size="iconSize"
        :color="iconColor"
        class="x-bullet-list__icon"
      />
      {{ item }}
    </li>
  </component>
</template>

<script>
export default {
  name: 'XBulletList',
  props: {
    tag: {
      type: String,
      default: 'ul'
    },
    items: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: 'check-circle'
    },
    iconSize: {
      type: String,
      default: 's'
    },
    iconColor: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-bullet-list
    margin 0
    padding 0
    list-style none

    &__item
      color: $colors.gray-darker

    &__icon
      vertical-align middle
</style>
