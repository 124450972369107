<template>
  <div
    :class="classes"
    :style="styles"
    @click="focus"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <textarea
      ref="textarea"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      v-bind="$attrs"
      v-on="listeners"
    />
    <x-icon
      v-if="showClearIcon"
      class="x-textarea__clear-icon"
      size="xs"
      name="x-circle"
      @click="clear"
    />
    <div class="x-textarea__border"></div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data: () => ({
    hovered: false,
    focused: false
  }),
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 's',
      validator: v => ['xs', 's'].includes(v)
    },
    clearable: {
      type: Boolean,
      default: true
    },
    ghost: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    passed: {
      type: Boolean,
      default: false
    },
    failed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => {
          this.$emit('input', e.target.value)
        },
        focus: e => {
          this.onFocus()
          if (this.$listeners.focus) {
            this.$listeners.focus(e)
          }
        },
        blur: e => {
          this.onBlur()
          if (this.$listeners.blur) {
            this.$listeners.blur(e)
          }
        }
      }
    },
    filled () {
      return this.value !== '' && this.value !== null
    },
    styles () {
      if (this.width !== undefined) {
        const w = typeof this.width === 'number' ? this.width + 'px' : this.width
        return { width: w }
      }

      return {}
    },
    classes () {
      return [
        'x-textarea',
        {
          'x-textarea--hovered': this.hovered,
          'x-textarea--focused': this.focused,
          'x-textarea--disabled': this.disabled,
          'x-textarea--ghost': this.ghost,
          'x-textarea--center': this.center,
          'x-textarea--filled': this.filled,
          'x-textarea--success': !this.focused && this.passed,
          'x-textarea--error': this.failed
        }
      ]
    },
    showClearIcon () {
      return !this.disabled && !this.readonly && this.clearable && this.filled && (this.focused || this.hovered)
    }
  },
  methods: {
    onFocus () {
      this.focused = true
    },
    onBlur () {
      this.focused = false
    },
    onMouseEnter () {
      this.hovered = true
    },
    onMouseLeave () {
      this.hovered = false
    },
    focus () {
      this.$refs.textarea.focus()
    },
    clear () {
      this.$emit('input', '')
      this.focus()
    }
  }
}
</script>

<style lang="stylus">
$input-text
  color: $colors.gray-dark
  font-family $font-family
  font-size: $font-sizes.text
  font-style normal
  font-weight: $font-weights.normal
  line-height 18px

.x-textarea
  display inline-flex
  align-items flex-start
  width 100%
  padding $space * 2
  background-color #fff
  box-sizing border-box
  border-radius $space
  position relative
  cursor text
  @extend $input-text

  textarea
    width 100%
    flex-shrink 1
    flex-grow 1
    padding 0
    margin 0
    background transparent
    border none
    outline none
    resize none
    @extend $input-text

    &::placeholder
      @extend $input-text

  &__border
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    border-width 1px
    border-color: $colors.gray
    border-style solid
    border-radius $radius
    transition border-color .15s
    pointer-events none

  &__clear-icon
    flex-shrink 0
    margin-left 6px
    color: $colors.gray
    cursor pointer

    &:hover
      color: $colors.gray-darkest-1

    &:active
      color: $colors.gray-darkest-2

  &--hovered &__border
    border-color: $colors.gray-darker

  &--focused, &:active
    color: $colors.gray-darkest-2

    .x-input__border
      border-color: $colors.primary
      border-width 2px

    textarea
      color: $colors.gray-darkest-2

  &--filled
    color: $colors.gray-darkest-2

    textarea
      color: $colors.gray-darkest-2

  &--success &__border
    border-color: $colors.success

  &--error, &--error:active
    .x-textarea__border
      border-color: $colors.error

  &--disabled, &--disabled:active
    color: $colors.gray
    background-color: $colors.gray-lightest-1
    cursor not-allowed

    .x-textarea__border
      border-width 1px
      border-color: $colors.gray-light

    textarea
      cursor not-allowed

  &--ghost, &--ghost:active
    background-color transparent
    .x-input__border
      border-color transparent

  &--center textarea
    text-align center
</style>
