<template>
  <v-popover
    :boundaries-element="bindTo"
    :placement="placement"
  >
    <button
      type="button"
      class="grid-action-menu"
    >
      <slot>
        <x-icon
          name="action-menu"
          class="grid-action-menu__icon"
        />
      </slot>
    </button>

    <template slot="popover">
      <ul class="grid-action-menu__list">
        <li
          v-for="(action, index) in actions"
          :key="index"
          class="grid-action-menu__list-item"
        >
          <x-btn
            v-close-popover="isClosed"
            type="button"
            class="button_content_grid-action"
            block
            @click="onActionCb(action)"
          >
            <x-icon
              v-if="action.iconName"
              :name="action.iconName"
              class="button__icon"
            />
            <span>
              {{ action.label }}
            </span>
          </x-btn>
        </li>
      </ul>
    </template>
  </v-popover>
</template>

<script>

export default {
  props: {
    actions: {
      type: Array,
      required: true
    },
    row: {
      type: Object,
      default: () => ({})
    },
    bindTo: {
      type: String,
      default: 'window'
    },
    placement: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isClosed: true
    }
  },

  methods: {
    async onActionCb (action) {
      this.isClosed = true

      if (action.cb && typeof action.cb === 'function') {
        action.cb(this.row)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .grid-action-menu
    width 36px
    height 36px
    border-radius 4px
    cursor pointer

    &:hover
      background-color #e5e5e9

    &__list
      background #fdfdfd
      border-radius 4px
      box-shadow 0px 6px 16px rgba(5, 2, 55, .12)

    &__icon
      width 4px
      height 10px
      vertical-align middle
      color #5b40ff

    &__list-item
      list-style none
      font-size 14px

      >>> .button
        border-radius 0
</style>
