import Vue from 'vue'
import VueRouter from 'vue-router'
import { setApiToken } from '@/utils/auth/shared'
import { authorized } from './guards'
import RouteNames from './names'
import XSupplyCalendar from '@/views/XSupplyCalendar/XSupplyCalendar'
import XStub from '@/views/XStub/XStub'
import XProducts from '@/views/XProducts/XProducts'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: RouteNames.STUB,
  component: XStub,
  props: true
}, {
  path: '/calendar',
  name: RouteNames.CALENDAR,
  component: XSupplyCalendar
}, {
  path: '/products',
  name: RouteNames.PRODUCTS,
  component: XProducts
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { xw_token: token } = to.query

  if (token) {
    setApiToken(token)
    authorized(to, from, () => next({
      ...to,
      query: {
        ...to.query,
        cabinet_type: undefined,
        xw_token: undefined
      }
    }))
    return
  }

  authorized(to, from, next)
})

export default router
