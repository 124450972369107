<template>
  <x-placeholder
    icon="table-empty"
    desc="Заявки на поставку отсутствуют"
  >
    <template #bottom>
      <x-btn @click="handleSupplyCreate">
        Добавить заявку
      </x-btn>
    </template>
  </x-placeholder>
</template>

<script>
export default {
  name: 'XSupplyCalendarPlaceholder',
  inject: ['handleSupplyCreate']
}
</script>
