const appendParamsToCookie = (cookie, params) => {
  let newCookie = cookie

  Object.keys(params).forEach(key => {
    newCookie += ` ${key}=${params[key]};`
  })

  return newCookie
}

export const getCookie = (name) => {
  const cookies = document.cookie
    .split(';')
    .reduce((result, cookie) => {
      const [name, value] = cookie.split('=')
      result[name.trimStart()] = value
      return result
    }, {})

  return cookies[name]
}

export const setCookie = (name, value, params) => {
  const cookie = appendParamsToCookie(`${name}=${value};`, params)

  document.cookie = cookie
}

export const removeCookie = (name, params) => {
  const cookie = appendParamsToCookie(`${name}= ;`, {
    ...params,
    'max-age': '0'
  })
  document.cookie = cookie
}
