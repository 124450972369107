export const PLANS_CHANGED = 'PLANS_CHANGED'
export const CHOOSE_ANOTHER_DATE = 'CHOOSE_ANOTHER_DATE'
export const SELF_REASON = 'SELF_REASON'

export const cancelReasons = {
  [PLANS_CHANGED]: 'Изменились планы',
  [CHOOSE_ANOTHER_DATE]: 'Выберу другую дату',
  [SELF_REASON]: 'Свой вариант'
}

export const getReasonByType = (type) => cancelReasons[type]
