var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"x-supply-calendar-list"},[(_vm.isLoading)?_c('x-loader'):[(_vm.data.length)?_c('div',[_c('x-grid',{attrs:{"auto":"","unfoldable":"","columns":_vm.columns,"cells":_vm.data},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.id)+" "),(row.supply_attachments && row.supply_attachments.length)?_c('x-unfold-button',{attrs:{"active":row.isUnfold},on:{"click":function($event){return _vm.$set(row, 'isUnfold', !row.isUnfold)}}}):_vm._e()],1),_c('div',{staticClass:"caption gray-darker--text"},[_vm._v(_vm._s(row.seller_name))])]}},{key:"status",fn:function(ref){
var status = ref.status;
var row = ref.row;
return [_c('div',[_c('x-status',{attrs:{"label":status}})],1),(status === _vm.statuses.DIVERGENCE)?_c('div',[_c('x-btn',{staticClass:"x-supply-calendar-list__attachment",attrs:{"prepend":"attachment","size":"xs","ghost":""},on:{"click":function($event){return _vm.handleShowAttachmentModal(row.id)}}},[_vm._v(" Прикрепить ТОРГ-12 или УПД ")])],1):_vm._e()]}},{key:"deliveryDate",fn:function(ref){
var deliveryDate = ref.deliveryDate;
var day_period = ref.row.day_period;
return [_vm._v(" "+_vm._s(_vm.formatDate(deliveryDate))+", "+_vm._s(_vm.formatPeriod(day_period))+" ")]}},{key:"unfold",fn:function(ref){
var supply_attachments = ref.row.supply_attachments;
return [(supply_attachments && supply_attachments.length)?_c('x-attachments-list',{attrs:{"data":supply_attachments}}):_vm._e()]}},{key:"actions",fn:function(ref){
var supply = ref.row;
return [_c('div',{staticClass:"text-right"},[_c('x-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Информация по поставке' }),expression:"{ content: 'Информация по поставке' }"}],attrs:{"color":"gray","size":"xs","icon":"list","ghost":""},on:{"click":function($event){return _vm.handleShowSupplyInfo(supply.id)}}}),(_vm.isShowCancelButton(supply.status))?_c('x-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Отменить заявку' }),expression:"{ content: 'Отменить заявку' }"}],attrs:{"color":"gray","size":"xs","icon":"x","ghost":""},on:{"click":function($event){return _vm.handleSupplyCancel(supply.id)}}}):_vm._e()],1)]}}],null,false,3130188951)})],1):_c('x-supply-calendar-placeholder')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }