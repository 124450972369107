<template>
  <div
    class="grid-container"
    :class="containerClasses"
  >
    <div
      v-if="freezedColumns.length"
      class="grid-container__freeze"
    >
      <table
        class="grid grid_viewtype_freeze"
        :class="{ 'stripped': stripped, 'auto': auto, 'simple': simple, 'bordered': bordered }"
      >
        <grid-head
          :class="{ 'hide': hideHeader }"
          :columns="columns"
          :sort-orders="sortOrders"
          :sort-key="sortKey"
          :actions="actions"
          :show-actions="!freezedColumns"
          :actions-heading="actionsHeading"
          freezed
          :selectable="selectable"
          :all-selected="allChecked"
          @sort-by="sortBy"
          @check-all="checkAll"
        >
          <template #head="{ column }">
            <slot
              name="head"
              :column="column"
            />
          </template>
        </grid-head>

        <grid-body
          v-bind="$props"
          freezed
          :selectable="selectable"
          :sort-orders="sortOrders"
          :sort-key="sortKey"
          :unfoldable="unfoldable"
          :cells="filteredCells"
          :auto="auto"
          :errors="computedErrors"
          :error-description-field="errorDescriptionField"
          :show-actions="!freezedColumns"
          :checked-cells-ids="checkedCellsIds"
          :select-by="selectBy"
          @cell-clicked="$emit('cell-clicked', $event)"
          @cell-checked="$emit('cell-checked', $event)"
        >
          <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </grid-body>
      </table>
    </div>

    <div
      :class="{ 'grid-container__scrollable': freezedColumns.length }"
    >
      <table
        class="grid"
        :class="{ 'stripped': stripped, 'auto': auto, 'simple': simple, 'bordered': bordered }"
      >
        <grid-head
          :class="{ 'hide': hideHeader }"
          :columns="columns"
          :selectable="selectable"
          :sort-orders="sortOrders"
          :sort-key="sortKey"
          :actions="actions"
          :show-actions="!freezedColumns"
          :actions-heading="actionsHeading"
          :all-selected="allChecked"
          @sort-by="sortBy"
          @check-all="checkAll"
        >
          <template #head="{ column }">
            <slot
              name="head"
              :column="column"
            />
          </template>
        </grid-head>

        <grid-body
          v-bind="$props"
          :selectable="selectable"
          :unfoldable="unfoldable"
          :sort-orders="sortOrders"
          :sort-key="sortKey"
          :cells="filteredCells"
          :auto="auto"
          :errors="computedErrors"
          :error-description-field="errorDescriptionField"
          :show-actions="!freezedColumns"
          :checked-cells-ids="checkedCellsIds"
          :select-by="selectBy"
          @cell-clicked="$emit('cell-clicked', $event)"
          @cell-checked="$emit('cell-checked', $event)"
        >
          <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </grid-body>
      </table>
    </div>

    <div
      v-if="actions.length && freezedColumns.length"
      class="grid-container__actions"
    >
      <table
        class="grid"
        :class="{ 'stripped': stripped, 'auto': auto, 'simple': simple, 'bordered': bordered }"
      >
        <grid-head
          :class="{ 'hide': hideHeader }"
          :columns="actionColumns"
          :sort-orders="sortOrders"
          :sort-key="sortKey"
          :actions="actions"
          :show-actions="!freezedColumns"
          :actions-heading="actionsHeading"
          freezed
          :selectable="selectable"
          :all-selected="allChecked"
          @sort-by="sortBy"
          @check-all="checkAll"
        >
          <template #head="{ column }">
            <slot
              name="head"
              :column="column"
            />
          </template>
        </grid-head>

        <grid-body
          v-bind="$props"
          freezed
          :selectable="selectable"
          :sort-orders="sortOrders"
          :sort-key="sortKey"
          :unfoldable="unfoldable"
          :cells="filteredCells"
          :columns="actionColumns"
          :auto="auto"
          :errors="computedErrors"
          :error-description-field="errorDescriptionField"
          show-actions
          :checked-cells-ids="checkedCellsIds"
          :select-by="selectBy"
          @cell-clicked="$emit('cell-clicked', $event)"
          @cell-checked="$emit('cell-checked', $event)"
        >
          <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </grid-body>
      </table>
    </div>
  </div>
</template>

<script>
import GridHead from './components/XGridHead/XGridHead.vue'
import GridBody from './components/XGridBody/XGridBody.vue'

export default {
  name: 'XGrid',
  components: {
    GridHead,
    GridBody
  },

  props: {
    actionsHeading: {
      type: String,
      default: 'Действия'
    },
    columns: {
      type: Array,
      default: () => []
    },
    cells: {
      type: Array,
      default: () => []
    },
    clientId: {
      type: [String, Number],
      default: 0
    },
    actions: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    stripped: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    unfoldable: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    inner: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    errorDescriptionField: {
      type: String,
      default: ''
    },
    checkedCellsIds: {
      type: Array,
      default: () => []
    },
    selectBy: {
      type: String,
      default: 'id'
    },
    defaultSortBy: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      sortOrders: {},
      sortKey: ''
    }
  },

  computed: {
    computedErrors () {
      const errors = []

      Object.keys(this.errors).forEach(key => {
        if (this.errors[key].length) {
          const [, id] = key.split('-')

          errors.push(id)
        }
      })

      return errors
    },

    filteredCells () {
      const sortKey = this.sortKey
      let cells = this.cells

      if (sortKey) {
        const order = this.sortOrders[sortKey].key || 1

        cells = cells.slice().sort((a, b) => {
          let firstEl = a
          let secondEl = b

          if (sortKey in a && sortKey in b) {
            if (typeof a[sortKey] === 'object') {
              const { sortableKey } = this.sortOrders[sortKey]
              if (sortableKey) {
                firstEl = a[sortKey][sortableKey]
                secondEl = b[sortKey][sortableKey]
              }
            } else {
              firstEl = a[sortKey]
              secondEl = b[sortKey]
            }
          }

          this.$emit('sort')

          return (firstEl === secondEl ? 0 : firstEl > secondEl ? 1 : -1) * order
        })
      }

      return cells
    },

    freezedColumns () {
      return this.columns.filter(c => c.freezed)
    },

    actionColumns () {
      return this.columns.map(c => ({ ...c, freezed: false }))
    },

    allChecked () {
      return this.filteredCells.every(cell => this.checkedCellsIds.includes(cell[this.selectBy]))
    },

    containerClasses () {
      return {
        'grid-container_freezed': this.freezedColumns.length,
        'grid-container_inner': this.inner,
        'grid-container_actions': this.actions.length
      }
    }
  },

  created () {
    this.initSortable()

    if (this.defaultSortBy) {
      this.sortBy({ key: this.defaultSortBy })
    }

    if (this.unfoldable) {
      this.initUnfoldable()
    }
  },

  methods: {
    initSortable () {
      this.columns.forEach((element) => {
        this.$set(this.sortOrders, element.key, { key: 0, field: element.key })
      })
    },

    initUnfoldable () {
      this.cells.forEach((cell) => {
        cell.isUnfold ?? this.$set(cell, 'isUnfold', false)
      })
    },

    sortBy (item) {
      const sortOrders = this.sortOrders[item.key].key

      this.sortKey = item.key
      this.sortOrders[item.key] = {
        key: sortOrders !== 0 ? sortOrders * -1 : sortOrders + 1,
        field: item.key,
        sortableKey: item.sortableKey
      }
    },

    checkAll (checked) {
      this.$emit('check-all', checked)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .grid-container
    position relative
    width 100%
    overflow hidden

    &_freezed
      .grid-container__freeze
        border-bottom 1px solid #d7d6d6

      >>> .grid__heading
        height 1px

      >>> .grid__body
        .grid__row
          &:hover
            .grid__cell
              background-color transparent

              &_freezed
                background-color #fdfdfd

        .grid__cell
          height 1px

      &:after
        content ""
        position absolute
        top 0
        bottom 0
        right 0
        width 20px
        height calc(100% - 20px)
        background linear-gradient(90deg, rgba(60, 60, 71, 0.08) 0%, rgba(60, 60, 71, 0) 100%)
        transform matrix(-1, 0, 0, 1, 0, 0)

    &_actions
      &:after
        content none

    &_inner
      .grid-container__freeze
        border-bottom none

      .grid-container__scrollable
        padding-bottom 0
        overflow visible

    &__freeze
    &__actions
      position absolute
      top 0
      left 0
      z-index 1
      width 100%
      overflow hidden
      pointer-events none

    &__scrollable
      padding-bottom 20px
      overflow-x auto

    &__actions
      >>> .grid__heading
        &:last-child
          position sticky
          right -1px
          background-color #fdfdfd
          border none

          .grid__heading-in
            position relative

            &:after
              content ""
              position absolute
              top 0
              bottom 0
              right 100%
              width 20px
              background linear-gradient(90deg, rgba(60, 60, 71, .08) 0%, rgba(60 ,60, 71, 0) 100%)
              transform matrix(-1, 0, 0, 1, 0, 0)

  .grid
    width 100%
    table-layout fixed
    border-collapse collapse

    .is-active
      color #5b40ff
      font-weight bold

    .sortable
      cursor pointer

    &.stripped
      .grid__row:nth-child(odd)
        .grid__cell
          background-color #f4f4f4

    &.auto
      table-layout auto

    &.bordered
      border 1px solid #e4e4e9

      >>>.grid__row
        border-top 1px solid #e4e4e9

      >>>.grid__heading
        background-color #f8f8fa

      >>>.grid__heading-in
        padding 10px

    &.simple
      table-layout auto

      >>>.grid__row
        &_heading
          .grid__heading
            background-color #f8f8fa
            border-bottom none

            @media (max-width 768px)
              background-color transparent

            &:first-child
              border-top-left-radius 5px
              border-bottom-left-radius 5px

            &:last-child
              border-top-right-radius 5px
              border-bottom-right-radius 5px

      >>> .grid__row
        &:nth-child(odd)
          .grid__cell
            @media (max-width 768px)
              background-color #f6f6f9

            &:first-child
              @media (max-width 768px)
                border-top-left-radius 8px
                border-bottom-left-radius 8px

            &:last-child
              @media (max-width 768px)
                border-top-right-radius 8px
                border-bottom-right-radius 8px

      >>>.grid__heading
        padding-top 10px
        padding-bottom 10px
        font-size 12px
        white-space nowrap
        color #7b7b85

      >>> .grid__cell
        @media (max-width 768px)
          display table-cell
          padding 10px 6px

        &:last-child
          @media (max-width 768px)
            border-bottom none

          .grid__cell-in
            text-align right

      >>> .grid__cell-in
        text-align left

        &:before
          @media (max-width 768px)
            display none

      >>> .grid__body
        .grid__row
          &:hover
            @media (max-width 768px)
              background-color inherit
</style>
