<template>
  <x-grid
    auto
    bordered
    :columns="columns"
    :cells="data"
    class="mb-5"
  >
    <template #date="{ date }">
      {{ formatDate(date, 'DD.MM.YYYY HH:mm') }}
    </template>
    <template #download="{ download }">
      <x-btn
        :href="download"
        color="gray"
        size="xs"
        icon="download"
        download
      />
    </template>
  </x-grid>
</template>

<script>
import { formatDate } from '@/helpers/dates'
import XGrid from '@/components/interface/XGrid/XGrid'
import columns from './columns'

export default {
  name: 'XAttachmentsList',
  components: {
    XGrid
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    formatDate
  }
}
</script>
