<template>
  <x-filter-block
    valign="bottom"
    :disabled="disableReset"
    v-on="$listeners"
  >
    <x-col
      v-for="field in computedFields"
      :key="field.id"
      class="col-xl-3"
    >
      <x-label size="xs">
        {{ field.label }}
      </x-label>
      <component
        :is="field.component"
        v-model="model[field.id]"
        v-bind="field.props"
        :disabled="disabled"
      />
    </x-col>
  </x-filter-block>
</template>

<script>
import XCol from '@/components/common/XCol/XCol'
import XFilterBlock from '@/components/common/XFilterBlock/XFilterBlock'
import XDatePicker from '@/components/interface/XDatePicker/XDatePicker'
import XSearch from '@/components/interface/XSearch/XSearch'
import selfFields from './fields'

export default {
  name: 'XSupplyCalendarFilter',
  components: {
    XCol,
    XFilterBlock,
    XDatePicker,
    XSearch
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    disabled: Boolean,
    disableReset: Boolean
  },
  data () {
    return {
      selfFields
    }
  },
  computed: {
    computedFields () {
      return this.selfFields.map(f => {
        if (this.fields[f.id]?.props && f.props) {
          return {
            ...f,
            props: {
              ...f.props,
              ...this.fields[f.id].props
            }
          }
        }

        return f
      })
    }
  }
}
</script>
