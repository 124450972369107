<template>
  <ValidationObserver
    ref="observer"
    class="x-form"
    tag="form"
    :name="name"
    @submit.prevent="submit"
  >
    <slot />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'XForm',
  components: {
    ValidationObserver
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    passiveValidation: Boolean
  },
  methods: {
    async submit () {
      if (this.passiveValidation) {
        this.$emit('submit')
      } else {
        const isValid = await this.$refs.observer.validate()

        if (isValid) {
          this.$emit('submit')
        }
      }
    },

    setErrors (...args) {
      this.$refs.observer.setErrors(...args)
    },

    validate (...args) {
      return this.$refs.observer.validate(...args)
    },

    resetValidation () {
      this.$refs.observer.reset()
    }
  }
}
</script>
